import { request } from "utils/request"
import { asyncAction } from "./common"
import { analyses} from "./"

const initialState = {
  // Options
  assetOverbooked: false,
  includeToday: false,
  includeOverdue: false,
  ignoreGreytasks: false,
  iapAllowOverbooking: false,
  sortingParameter: null,

  // Status
  currentPlannerId: null,
  hasDatabaseChanged: false,
  iapRunning: false,
  isInPlanningMode: false,
  isCurrentPlanner: false,
  sampleAllocating: false,
  showProjects: true,
  showAnalyses: true,
  showForecast: false,
  plannertechVersion: 0,
  allowTaskTransfer: true,
  directToMemberPlan: false,

  // For FE display
  changingIncludeToday: 0,
  plannertechShouldUpdate: false,
  discardingChanges: false,
  showLogoutConfirm: false,
  iapFromDate: null,
  iapToDate: null,
}

export function reducer(state = initialState, action) {
  switch (action.type) {
    case "teamStatus/fetchCurrent":
      return {
        ...state,
        // Options
        sortingParameter: action.res.options.sortingParameter,
        includeToday: action.res.options.iapToday,
        assetOverbooked: action.res.options.assetOverbooking,
        includeOverdue: action.res.options.includeOverdue,
        ignoreGreytasks: action.res.options.iapIgnoreWhiteTasks,
        iapAllowOverbooking: action.res.options.iapAllowOverbooking,
        allowTaskTransfer: action.res.options.allowTaskTransfer,
        directToMemberPlan: action.res.options.directToMemberPlan,

        // Status
        isInPlanningMode: !!action.res.status.currentPlannerId,
        currentPlannerId: action.res.status.currentPlannerId,
        isCurrentPlanner: action.res.status.isPlanner,
        sampleAllocating: action.res.status.sampleAllocating,
        iapRunning: action.res.status.iapRunning,
        hasDatabaseChanged: action.res.status.hasDatabaseChanged,
        plannertechVersion: action.res.status.plannertechVersion,

        // TODO: Remove this check for undefined when BE has been deployed for a while.
        showProjects: action.res.status.showProjects !== undefined ? action.res.status.showProjects : true,
        showAnalyses: action.res.status.showAnalyses !== undefined ? action.res.status.showAnalyses : true,
        showForecast: action.res.status.showForecast !== undefined ? action.res.status.showForecast : true,

        // For FE display
        plannertechShouldUpdate:
          state.plannertechVersion && state.plannertechVersion !== action.res.status.plannertechVersion,
      }
    case "teamStatus/start":
      return { ...state, isInPlanningMode: true, currentPlannerId: "self", isCurrentPlanner: true }
    case "teamStatus/accept":
      return { ...state, isInPlanningMode: false, currentPlannerId: null, isCurrentPlanner: false }
    case "teamStatus/discard/BEGIN":
      return { ...state, discardingChanges: true }
    case "teamStatus/discard":
      return {
        ...state,
        isInPlanningMode: false,
        currentPlannerId: null,
        isCurrentPlanner: false,
        discardingChanges: false,
      }
    case "teamStatus/changeIAPPriority":
      return { ...state, sortingParameter: action.res.options.sortingParameter }
    case "teamStatus/includeToday/BEGIN":
      const iapToday = action.args[0].iapToday
      return { ...state, includeToday: iapToday, changingIncludeToday: iapToday ? 1 : 2 }
    case "teamStatus/includeToday":
      return { ...state, changingIncludeToday: 0 }
    case "teamStatus/assetOverbooked":
      return { ...state, assetOverbooked: action.res.options.assetOverbooking }
    case "teamStatus/includeOverdue":
      return { ...state, includeOverdue: action.res.options.includeOverdue }
    case "teamStatus/ignoreGreytasks":
      return { ...state, ignoreGreytasks: action.res.options.iapIgnoreWhiteTasks }
    case "teamStatus/iapAllowOverbooking":
      return { ...state, iapAllowOverbooking: action.res.options.iapAllowOverbooking }
    case "teamStatus/allowTaskTransfer":
      return { ...state, allowTaskTransfer: action.res.options.allowTaskTransfer }
    case "teamStatus/directToMemberPlan":
      return { ...state, directToMemberPlan: action.res.options.directToMemberPlan }
    case "teamStatus/fetchLastSampleAllocation":
      return {...state, iapFromDate: action.res.iapFromDate, iapToDate: action.res.iapToDate}
    case "teamStatus/showLogoutConfirm":
      return {...state, showLogoutConfirm: action.res}
    case "teamStatus/setIAPconfiguration":
      return {...state, iapFromDate: action.res.iapFromDate, iapToDate: action.res.iapToDate}
    case "auth/logout":
      return initialState
    default:
      return state
  }
}

export const fetchCurrent = asyncAction("teamStatus/fetchCurrent", (dispatch) => {
  return request("GET", "/team-status").then((res) => {
    const state = window.store.getState()
    if (state.teamStatus.plannertechShouldUpdate) {
      window.localStorage.setItem("plannertechVersion", JSON.stringify(state.teamStatus.plannertechVersion))
      document.location.reload()
    }
    if (res.shouldUpdateOutlook){
      request("POST", "/members/load-outlook/1")
    }
    return res
  })
})

export const startPlanningMode = asyncAction("teamStatus/start", (dispatch) => {
  return request("POST", "/planning-mode/start")
})

export const acceptPlanningModeChanges = asyncAction("teamStatus/accept", (dispatch) => {
  return request("POST", "/planning-mode/accept")
})

export const discardPlanningModeChanges = asyncAction("teamStatus/discard", (dispatch) => {
  return request("POST", "/planning-mode/discard")
})

export const changeIAPPriority = asyncAction("teamStatus/changeIAPPriority", (dispatch, priority) => {
  return request("PATCH", "/team/options", { patch: priority }).then((res) => {
    return res
  })
})

export const includeToday = asyncAction("teamStatus/includeToday", (dispatch, patch) => {
  return request("PATCH", "/team/options", { patch }).then((res) => {
    dispatch(analyses.fetchPlanned())
    return res
  })
})

export const overbooking = asyncAction("teamStatus/assetOverbooked", (dispatch, patch) => {
  return request("PATCH", "/team/options", { patch }).then((res) => {
    return res
  })
})

export const includeOverdue = asyncAction("teamStatus/includeOverdue", (dispatch, patch) => {
  return request("PATCH", "/team/options", { patch }).then((res) => {
    return res
  })
})

export const ignoreGreytasks = asyncAction("teamStatus/ignoreGreytasks", (dispatch, patch) => {
  return request("PATCH", "/team/options", { patch }).then((res) => {
    return res
  })
})

export const iapAllowOverbooking = asyncAction("teamStatus/iapAllowOverbooking", (dispatch, patch) => {
  return request("PATCH", "/team/options", { patch }).then((res) => {
    return res
  })
})

export const allowTaskTransfer = asyncAction("teamStatus/allowTaskTransfer", (dispatch, patch) => {
  return request("PATCH", "/team/options", { patch }).then((res) => {
    return res
  })
})

export const directToMemberPlan = asyncAction("teamStatus/directToMemberPlan", (dispatch, patch) => {
  return request("PATCH", "/team/options", { patch }).then((res) => {
    return res
  })
})

export const fetchLastSampleAllocation = asyncAction("teamStatus/fetchLastSampleAllocation", (dispatch) => {
  return request("GET", `/team/iap-dates`).then((res) => {
    return res
  })
})

export function showLogoutConfirm (res) {
  return { type: "teamStatus/showLogoutConfirm", res }
}

export const setIAPconfiguration = asyncAction("teamStatus/setIAPconfiguration", (dispatch, from, to) => {
  return request("PATCH", "/team/iap-dates", { patch : { iapFromDate: from, iapToDate: to }})
})
