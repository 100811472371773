import React, { useState, useMemo } from "react"
import { useSelector, useDispatch } from "react-redux"
import { useParams } from "react-router-dom"
import { useSwipeable } from "react-swipeable"
import { DateTime } from "luxon"

import "./index.scss"

import { ScheduleMemberHours, ScheduleElement, ErrorBoundary, ScheduleButtonsMobile } from "components"
import { visual } from "state_management"

import { getTaskInterval } from "utils/scripts/schedule"

export default function ScheduleScreenMobile() {
  const { memberId } = useParams()
  const dispatch = useDispatch()
  const allMembers = useSelector((state) => state.members)
  const allWhiteTasks = useSelector((state) => state.whiteTasks)
  const allAnalysisTasks = useSelector((state) => state.analysisTasks)
  const allProjectActions = useSelector((state) => state.projectActions.plannedProjectActions)
  const memberTimezone = useSelector((state) => state.auth.memberTimezone)
  const scheduleMondayDate = useSelector((state) => state.visual.scheduleMondayDate)
  const mondayDate = DateTime.fromISO(scheduleMondayDate)
  const [activeDate, setActiveDate] = useState(DateTime.local().setZone(memberTimezone).startOf("day"))

  const members = memberId
    ? [allMembers[memberId]]
    : Object.values(allMembers)
        .filter((member) => member.showOnPlan)
        .filter((member) => !member.archived)

  const setMondayDate = (date) => {
    const thisMonday = date.startOf("week")
    if (!mondayDate.hasSame(thisMonday, "day")) {
      dispatch(visual.changeMondayDate(thisMonday.toISO()))
    }
  }

  const handlers = useSwipeable({
    onSwipedLeft: () => {
      setMondayDate(activeDate.plus({ days: 1 }))
      setActiveDate(activeDate.plus({ days: 1 }))
    },
    onSwipedRight: () => {
      setMondayDate(activeDate.minus({ days: 1 }))
      setActiveDate(activeDate.minus({ days: 1 }))
    },
    delta: 150, // Sensitivity of swipe
  })

  const preprocessedItems = useMemo(() => {
    return Object.values(allWhiteTasks)
      .concat(Object.values(allAnalysisTasks))
      .concat(Object.values(allProjectActions))
      .map(item => {
        // Parse with explicit member's timezone
        const startDateTime = DateTime.fromISO(item.start)
        const endDateTime = DateTime.fromISO(item.end)

        return {
          ...item,
          startDateTime,
          endDateTime,
          startDate: startDateTime.toISODate(),
          endDate: endDateTime.toISODate(),
        }
      })
  }, [allWhiteTasks, allAnalysisTasks, allProjectActions])

  // Create date-to-items mapping
  const dateToItemsMap = useMemo(() => {
    const map = {}
    const dateString = activeDate.startOf("day").toISODate()
    map[dateString] = { starts: [], ends: [] }

    preprocessedItems.forEach(item => {
      if (item.memberIds && item.memberIds.includes(memberId || "")) {
        if (map[item.startDate]) {
          map[item.startDate].starts.push(item)
        }
        if (map[item.endDate]) {
          map[item.endDate].ends.push(item)
        }
      }
    })

    return map
  }, [preprocessedItems, activeDate, memberId])

  // Precompute date start timestamps
  const dateStartTimestamps = useMemo(() => {
    return [activeDate.startOf('day').toMillis()]
  }, [activeDate])

  return (
    <div className="ScheduleScreenMobile" {...handlers}>
      <ScheduleButtonsMobile
        content={activeDate.toFormat("ccc MMM d")}
        setToday={() => {
          setMondayDate(DateTime.local().setZone(memberTimezone).startOf("week"))
          setActiveDate(DateTime.local().setZone(memberTimezone).startOf("day"))
        }}
        goBackOneDay={() => {
          setMondayDate(activeDate.minus({ days: 1 }))
          setActiveDate(activeDate.minus({ days: 1 }))
        }}
        goForwardOneDay={() => {
          setMondayDate(activeDate.plus({ days: 1 }))
          setActiveDate(activeDate.plus({ days: 1 }))
        }}
      />
      <div className="ScheduleScreenMobileContent">
        {members.map((member) => {
          const extended = true
          const memberTasks = preprocessedItems.filter((item) => item.memberIds.includes(member.id))

          const [minHeight, maxHeight] = getTaskInterval(
            memberTasks,
            [activeDate.startOf("day")],
            dateStartTimestamps,
            member.workHours,
            member.timezone,
            memberTimezone,
            extended,
            dateToItemsMap
          )

          const dayLength = maxHeight - minHeight
          return (
            <div
              className="ScheduleScreenMobileMember"
              key={member.id}
              style={{ height: 112 * dayLength + 37.59 + "px" }}
            >
              <h4>{member.initials}</h4>
              <div className="ScheduleScreenMobileMemberContent">
                <ErrorBoundary>
                  <ScheduleMemberHours
                    extended={extended}
                    minMemberHeight={minHeight}
                    maxMemberHeight={maxHeight}
                    member={member}
                  />
                  <ScheduleElement
                    extended={extended}
                    minMemberHeight={minHeight}
                    maxMemberHeight={maxHeight}
                    memberId={member.id}
                    date={activeDate.startOf("day").toISO()}
                    isMobile={true}
                  />
                </ErrorBoundary>
              </div>
            </div>
          )
        })}
      </div>
    </div>
  )
}

