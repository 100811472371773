import React, { useState, useEffect, useRef } from "react"
import { useSelector, useDispatch } from "react-redux"

import { Toolbar } from "./Toolbar"
import { AiOutlinePlus } from "react-icons/ai"
import { FaMicrophone } from "react-icons/fa"
import { FaStop, FaArrowUp, FaArrowLeftLong } from "react-icons/fa6";

import { Loading } from "components"
import { librarian, message } from "state_management"

import "./index.scss"
import logo from "utils/stylesheets/logo/logo.png"
import isOnMobile from "utils/scripts/isOnMobile"
import { Button} from "react-bootstrap"
import { analyzeAudioBlob } from "utils/scripts/analyseAudio"

export default function LibrarianScreen() {
  const dispatch = useDispatch()

  const [input, setInput] = useState("")
  const [isRecording, setIsRecording] = useState(false);
  const [mediaRecorder, setMediaRecorder] = useState(null);
  const [insertionLength, setInsertionLength] = useState(0);
  const [cursorPosition, setCursorPosition] = useState(0);
  const [textInserted, setTextInserted] = useState(false);
  const [isFirstLoad, setIsFirstLoad] = useState(true)
  const [isHistoryVisible, setIsHistoryVisible] = useState(false);

  const messagesEndRef = useRef(null)
  const textareaRef = useRef(null);

  const userId = useSelector((state) => state.auth.memberId)
  const initials = useSelector((state) => state.members[userId].initials)
  const conversation = useSelector((state) => state.librarian.conversation)
  const chatbotId = useSelector((state) => state.librarian.selectedChatbot.id)
  const isLoading = useSelector((state) => state.librarian.isLoading)
  const isFetching = useSelector((state) => state.librarian.isFetching)
  const companyName = useSelector((state) => state.auth.companyName)
  const chatbots = useSelector((state) => state.librarian.chatbots)
  const defaultChatbot = useSelector((state) => state.auth.defaultChatbot)
  const selectedChatbot = useSelector((state) => state.librarian.selectedChatbot)

  const toggleLibrarianHistory = () => setIsHistoryVisible(!isHistoryVisible);

  useEffect(() => {
    if (isFirstLoad) {
      setIsFirstLoad(false)
      dispatch(librarian.fetchRepositories())
      dispatch(librarian.fetchChatbots())
    }
  }, [dispatch, isFirstLoad])

  useEffect(() => {
    if (chatbots.length > 0 && defaultChatbot) {
      const selectedChatbot = chatbots.find(chatbot => chatbot.id === defaultChatbot)
      dispatch(librarian.setChatbot(selectedChatbot))
    }
  }, [chatbots, defaultChatbot, dispatch])

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" })
  }

  useEffect(() => {
    scrollToBottom()
  }, [conversation])

  useEffect(() => {
    if (textareaRef.current) {
      adjustTextareaHeight(textareaRef.current);
    }
  }, [input]);

  useEffect(() => {
    if (insertionLength > 0 && textareaRef.current && textInserted) {
      textareaRef.current.focus();
      const startPos = cursorPosition - insertionLength;
      const endPos = cursorPosition;

      textareaRef.current.setSelectionRange(startPos, endPos);
      setTextInserted(false);
    }
  }, [insertionLength, cursorPosition, textInserted])

  if (isLoading) {
    return <Loading />
  }
  const ChatMessage = ({ messageInfo, isLoading = false }) => (
    <div className={`chat-message-container ${isLoading || messageInfo.isBot ? "bot-message" : "user-message"}`}>
      {isLoading || messageInfo.isBot ? (
        <div className="chat-message-icon">
          <img src={logo} alt="librarian logo" className="librarian-logo" />
        </div>
      ) : (
        <div className="chat-message-icon">
          {initials.slice(0, 2)}
        </div>
      )}
      {!isLoading ? (
        <div className="chat-message">
          {messageInfo.message.split(/\r?\n|\r|\n/g).map((line, index) => <p key={index}>{line}</p>)}
          {messageInfo.links?.length > 0 && messageInfo.links.map((link, index) => <a key={index} target={link.url.includes(".pdf") ? "_self" : "_blank"} href={link.url} rel="noopener noreferrer">{link.tag}</a>)}
        </div>) : <div className="chat-message thinking-dots" />}
    </div>
  )



  const checkToolbar = (chatbotId) => {
    if (chatbotId === "") {
      return [true, "chatbot"]
    }
    return [false, ""]
  }

  const handleSubmit = (event) => {
    event.preventDefault()
    const toolbar = checkToolbar(chatbotId)
    if (toolbar[0]) {
      return dispatch(message.warning(`Please select a ${toolbar[1]} first`))
    }
    if (!isFetching) {
      const question = input
      setInput("")
      if (event.type === "submit") {
        event.target[1].style.height = "auto"
      } else {
        event.target.style.height = "auto"
      }

      dispatch(librarian.addToConversation({ message: question, isBot: false }))
      dispatch(librarian.getAnswer({ message: question, isBot: false }, chatbotId)).catch((err) => {
        dispatch(librarian.addToConversation({ message: err.message, isBot: true }))
      })
    }
  }

  const handleKeyDown = (event) => {
    if (event.key === "Enter" && !event.shiftKey) {
      handleSubmit(event)
    }
  }

  function adjustTextareaHeight(target) {
    const textarea = target;

    textarea.style.overflowY = 'hidden';
    textarea.style.height = 'auto';
    textarea.style.height = `${textarea.scrollHeight}px`;
    textarea.style.overflowY = 'auto';
  }

  const startRecording = async () => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
      const recorder = new MediaRecorder(stream);
      setMediaRecorder(recorder);

      let audioChunks = [];

      recorder.addEventListener('dataavailable', event => {
        audioChunks.push(event.data);
      });

      recorder.addEventListener('stop', () => {
        const audioBlob = new Blob(audioChunks, { type: 'audio/wav' });
        // Send audioBlob to backend here
        analyzeAudioBlob(audioBlob).then(isSpeechDetected => {
          if (isSpeechDetected) {
            dispatch(librarian.getSpeechToTextQuestion(audioBlob)).then((res) => {
              setInput(prevInput => {
                  const newText = res.text.trim();
                  const beforeCursor = prevInput.substring(0, cursorPosition);
                  const afterCursor = prevInput.substring(cursorPosition);

                  const updatedInput = `${beforeCursor}${newText}${afterCursor}`;

                  const newCursorPosition = beforeCursor.length + newText.length;

                  setCursorPosition(newCursorPosition);
                  setInsertionLength(newText.length);
                  setTextInserted(true);

                  return updatedInput;
              });
          });
          } else {
            return dispatch(message.warning(`No sound detected. Please try again.`))
          }
        });
        audioChunks = [];
      });

      recorder.start(500);
      setIsRecording(true);
    } catch (error) {
      return dispatch(message.warning(`No microphone detected. Please try again.`))
    }
  };

  const stopRecording = () => {
    if (mediaRecorder) {
      mediaRecorder.stop();
      mediaRecorder.stream.getTracks().forEach(track => track.stop());
      setIsRecording(false);
    }
  };

  const toggleRecording = () => {
    if (isRecording) {
      stopRecording();
    } else {
      startRecording();
    }
  };

  return (
    <div className="LibrarianScreen-main-container">
      <div className={`Librarian-history ${isHistoryVisible && isOnMobile ? 'visible' : ''}`}>
        <div className="button-container">
          {isOnMobile && (
            <Button variant="primary" className="go-back-to-chat" onClick={toggleLibrarianHistory}>
              <FaArrowLeftLong title={"Go back to chat"} />
            </Button>
          )}
          <Button className="Librarian-new-chat-button" onClick={() => {
            dispatch(librarian.refreshChat())
            toggleLibrarianHistory()
          }}>
            <AiOutlinePlus />
            New chat
          </Button>
        </div>
        <h2 className="Librarian-history-conversations" style={{ textAlign: "center" }}>
          Version 2.0
        </h2>
      </div>
      <div className="chat-window">
        <Toolbar toggleLibrarianHistory={toggleLibrarianHistory}/>
        <span style={{top: "4em", left: "12em", position: "absolute", fontWeight: 600, fontSize: 24, color: "teal"}}>{selectedChatbot.name}</span>
        <div className="chat-messages">
          {conversation.length === 0 && (
            <div className="chat-messages-empty-board">
              <h1>{companyName}<br/>QC LIBRARIAN</h1>
            </div>
          )}
          {conversation.map((messageInfo, index) => (
            <ChatMessage key={index} messageInfo={messageInfo} />
          ))}
          {isFetching && <ChatMessage isLoading={true} />}
          <div ref={messagesEndRef} />
        </div>
        <div className="chat-config">

          <form onSubmit={handleSubmit} className="chat-input">
            <button type="button" className="microphone-button" onClick={toggleRecording}>
              {isRecording ? <FaStop /> : <FaMicrophone />}
            </button>
            <textarea
              value={input}
              onChange={(e) => {
                setInput(e.target.value)
                }
              }
              onKeyDown={handleKeyDown}
              onInput={(e) => {
                setCursorPosition(e.target.selectionStart);
              }}
              onClick={(e) => {
                setCursorPosition(e.target.selectionStart);
              }}
              rows={getRows(input)}
              placeholder="Type your message..."
              ref={textareaRef}
            />
            <button
              className="send-button"
              type="submit"
            >
              <FaArrowUp/>
            </button>
          </form>
        </div>
      </div>
    </div>
  )
}

// used to determine the number of rows in the textarea. Currently has maximum of 5 rows
const getRows = (input) => {
  const lines = input.split(/\r?\n|\r|\n/g)
  return lines.length > 5 ? 5 : lines.length
}
