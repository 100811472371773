import React, { useEffect, useState } from "react"
import { useSelector, useDispatch } from "react-redux"
import { FiPlus, FiSettings, FiCheck } from "react-icons/fi"
import { RiShieldUserLine } from "react-icons/ri"
import Loading from "components/Loading"
import Popup from "components/Popup"
import { ScheduleButtonsAdmin } from "components"
import { ChatbotConfig } from "components/Config"
import CustomerConfig from "./CustomerConfig"
import AdminsConfig from "./AdminsConfig"
import GlobalMessageConfig from "./GlobalMessageConfig"

import { RepositoryConfig } from "components/Config"
import { customerAdministration, auth, repositories, visual, librarian } from "state_management"
import "./index.scss"
import { Route, Routes, Navigate, useNavigate } from "react-router-dom"
import RepositoryScreen from "screens/RepositoryScreen"
import { Spinner } from "react-bootstrap"

export default function CustomerAdministratorScreen() {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [isFirst, setIsFirst] = useState(true)
  const [showConfig, setShowConfig] = useState(false)
  const [showAdmins, setShowAdmins] = useState(false)
  const [showRepositoryConfig, setShowRepositoryConfig] = useState(false)
  const [showChatbotConfig, setShowChatbotConfig] = useState(false)
  const [repository, setRepository] = useState(null)
  const [chatbot, setChatbot] = useState(null)
  const [currentCustomer, setCurrentCustomer] = useState(null)
  const isLoading = useSelector((state) => state.customerAdministration.isLoadingCustomers)
  const isLoadingGlobalRepositories = useSelector((state) => state.repositories.isLoadingGlobalRepositories)
  const customers = useSelector((state) => state.customerAdministration.customers)
  const sortedCustomers = Object.values(customers).sort((a, b) => a.name.localeCompare(b.name))
  const globalRepositories = useSelector((state) => state.repositories.globalRepositories)
  const chatbots = useSelector((state) => state.librarian.chatbots)
  const showGlobalMessage = useSelector((state) => state.visual.showGlobalMessageConfig)

  useEffect(() => {
    if (showGlobalMessage) {
      const textarea = document.getElementById("input-field-id")
      if (textarea) {
        textarea.style.height = textarea.scrollHeight + 'px';
      }
    }
  }, [showGlobalMessage])

  if (isFirst) {
    dispatch(customerAdministration.fetchCustomers())
    dispatch(repositories.fetchGlobal())
    dispatch(librarian.fetchAdminChatbots())
    setIsFirst(false)
  }

  if (isFirst || isLoading || isLoadingGlobalRepositories) {
    return <Loading />
  }

  return (
    <div className="Customer-main-container">
      {showConfig && (
        <Popup nonScrollable onCancel={() => setShowConfig(false)}>
          <CustomerConfig customer={currentCustomer} onClose={() => setShowConfig(false)} />
        </Popup>
      )}
      {showRepositoryConfig && (
        <Popup nonScrollable onCancel={() => setShowRepositoryConfig(false)}>
          <RepositoryConfig
            customerAdminView={true}
            repositoryData={repository}
            onClose={() => setShowRepositoryConfig(false)}
          />
        </Popup>
      )}
      {showChatbotConfig && (
        <Popup nonScrollable onCancel={() => setShowChatbotConfig(false)}>
          <ChatbotConfig
            customerAdminView={true}
            chatbotData={chatbot}
            repositories={globalRepositories}
            onCancel={() => setShowChatbotConfig(false)}
          />
        </Popup>
      )}

      {showAdmins && (
        <Popup className="center popup-autoWidth" nonScrollable onCancel={() => setShowAdmins(false)}>
          <AdminsConfig customer={currentCustomer} />
        </Popup>
      )}

      {showGlobalMessage && (
        <Popup
          className="center popup-autoWidth global-message-edit"
          nonScrollable
          onCancel={() => dispatch(visual.showGlobalMessageConfig(false))}
        >
          <GlobalMessageConfig />
        </Popup>
      )}

      <div className="customer-administrator-container">
        <ScheduleButtonsAdmin type="customer-view" />
        <Routes>
          <Route path="/*"
            element={
              <div>
                <h2 className="customer-company-headline">Customers</h2>
                <div className="Customer-boxes-scroll">
                  {Object.values(sortedCustomers).map((customer) => (
                    <div
                      key={customer.id}
                      className="Customer-box Customer-box-color Customer-box-hovereffect"
                      onClick={() =>
                        dispatch(auth.accessCompany(customer.id)).then(() => {
                          navigate("/locations")
                        })
                      }
                    >
                      <div className="customer-seats">
                        <p>Seats: {customer.seatsUsed}</p>
                      </div>
                      <div className="Customer-box-inner">
                        <p>{customer.name}</p>
                      </div>
                      <RiShieldUserLine
                        className="Location-team-consume admin-icons-hovereffect"
                        title="Manage Admins"
                        onClick={(e) => {
                          e.stopPropagation()
                          setShowAdmins(true)
                          setCurrentCustomer(customer)
                        }}
                      />
                      <FiSettings
                        className="Customer-fiSettings"
                        title="Settings"
                        onClick={(e) => {
                          e.stopPropagation()
                          setShowConfig(true)
                          setCurrentCustomer(customer)
                        }}
                      />
                    </div>
                  ))}
                  <div className="Customer-box">
                    <div className="Customer-fiPlus-container">
                      <FiPlus
                        className="Customer-fiPlus"
                        title="Add customer"
                        onClick={() => {
                          setCurrentCustomer(null)
                          setShowConfig(true)
                        }}
                      />
                    </div>
                  </div>
                </div>
                <h2 className="customer-company-headline"> Global Repositories</h2>
                <div className="Customer-boxes-scroll">
                  {globalRepositories.map((repository) => (
                    <div
                      key={repository.id}
                      className="Customer-box Customer-box-hovereffect Location-repository-box-color"
                      onClick={() => navigate(`/customers/global-repositories/${repository.id}`)}
                    >
                      <div className="repository-storageUsed-text">
                        <p style={{ color: "white" }}>{(repository.storageUsed / 1000 / 1000).toFixed(1)} MB</p>
                      </div>
                      <div className="Customer-repository-box-inner">
                        <p>{repository.name}</p>
                      </div>
                      <div className="repository-processing-icon">
                        {repository.isProcessing ? (
                          <div className="repository-spinner">
                            <Spinner animation="border" size="sm" title="Currently procesing this repository" />
                          </div>
                        ) : repository.isProcessed ? (
                            <FiCheck title="This repository is processed" />
                          ) : null}
                      </div>
                      <FiSettings
                        className="Location-team-fiSettings admin-icons-hovereffect"
                        title="Settings"
                        onClick={(e) => {
                          e.stopPropagation()
                          setShowRepositoryConfig(true)
                          setRepository(repository)
                        }}
                      />
                    </div>
                  ))}
                  <div className="Customer-box">
                    <div className="Customer-fiPlus-container">
                      <FiPlus
                        className="Customer-fiPlus"
                        title="Add global repository"
                        onClick={() => {
                          setShowRepositoryConfig(true)
                          setRepository(null)
                        }}
                      />
                    </div>
                  </div>
                </div>
                <h2 className="customer-company-headline">Global Chatbots</h2>
                <div className="Customer-boxes-scroll">
                  {chatbots.map((chatbot) => chatbot.isGlobal && (
                    <div
                      key={chatbot.id}
                      className="Customer-box Customer-box-hovereffect Customer-chatbot-box-color"
                      onClick={() => {
                        setChatbot(chatbot)
                        setShowChatbotConfig(true)
                      }}
                    >
                      <div className="Customer-repository-box-inner">
                        <p>{chatbot.name}</p>
                      </div>
                    </div>
                  ))}
                  <div className="Customer-box">
                    <div className="Customer-fiPlus-container">
                      <FiPlus
                        className="Customer-fiPlus"
                        title="Add global chatbot"
                        onClick={() => {
                          setChatbot(null)
                          setShowChatbotConfig(true)
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            }
          />
          <Route path="/global-repositories/:repositoryId"
            element={<RepositoryScreen />}
          />
          <Route path="*" element={<Navigate to="/customers" />} />
        </Routes>
      </div>
    </div>
  )
}
