import { request } from "utils/request"
import { projects, loading } from "."
import { createMap, addToMap, replaceInMap, removeFromMap, asyncAction, getRange } from "./common"
import { DateTime } from "luxon"

const initialState = {
  unplannedProjectActions: {},
  plannedProjectActions: {},
  backlogProjectActions: {},
  unplanning: false,
  planable: [],
}

export function reducer(state = initialState, action) {
  let temp = { ...state }
  let tempProject
  switch (action.type) {
    case "multipleDrag/dropAllActions":
      action.res.items.forEach((x) => (temp.plannedProjectActions = replaceInMap(temp.plannedProjectActions, x)))
      return temp
    case "projects/fetch":
      let backlogActions = action.res.unplanned.filter((x) => x.stage === "BACKLOG")
      let unplannedActions = action.res.unplanned.filter((x) => x.stage === "TODO")
      return {
        ...state,
        plannedProjectActions: createMap(action.res.planned),
        unplannedProjectActions: createMap(unplannedActions),
        backlogProjectActions: createMap(backlogActions),
        shouldReApplyFilter: true,
      }
    case "projectActions/fetchUnplanned":
      return { ...state, unplannedProjectActions: createMap(action.res), shouldReApplyFilter: true }
    case "projectActions/fetchPlanned":
      return { ...state, plannedProjectActions: createMap(action.res) }
    case "projectActions/setMovable":
    case "projectActions/setLocation":
    case "projectActions/move":
      return {
        ...state,
        plannedProjectActions: replaceInMap(state.plannedProjectActions, action.res),
        // Remove if previously unplanned
        unplannedProjectActions: removeFromMap(state.unplannedProjectActions, action.res),
      }
    case "projectActions/setLocationUnplanned":
      return {
        ...state,
        unplannedProjectActions: replaceInMap(state.unplannedProjectActions, action.res),
      }
    case "projectActions/update": {
      const temp = { ...state };

      action.res.forEach((updatedAction) => {
        const { stage } = updatedAction;

        // Move Actions in SCRUM view
        if (stage === "TODO") {
          temp.backlogProjectActions = removeFromMap(temp.backlogProjectActions, updatedAction);
          temp.plannedProjectActions = removeFromMap(temp.plannedProjectActions, updatedAction);
          temp.unplannedProjectActions = addToMap(temp.unplannedProjectActions, updatedAction);
        } else if (stage === "BACKLOG") {
          temp.backlogProjectActions = addToMap(temp.backlogProjectActions, updatedAction);
          temp.plannedProjectActions = removeFromMap(temp.plannedProjectActions, updatedAction);
          temp.unplannedProjectActions = removeFromMap(temp.unplannedProjectActions, updatedAction);
        } else if (stage === "FINISHED" || stage === "PLANNED") {
          temp.backlogProjectActions = removeFromMap(temp.backlogProjectActions, updatedAction);
          temp.plannedProjectActions = addToMap(temp.plannedProjectActions, updatedAction);
          temp.unplannedProjectActions = removeFromMap(temp.unplannedProjectActions, updatedAction);
        }
      });

      return temp;
    }
    case "projectActions/reOrderColumns":
      if(action.res.column){
        if(action.res.column.includes("TODO")) {
          Object.keys(action.res.arr.TODO).forEach(((key) => {
            temp.unplannedProjectActions[key] = { ...temp.unplannedProjectActions[key], orderIndex: action.res.arr.TODO[key] }
          }))
        }
        if(action.res.column.includes("BACKLOG")) {
          Object.keys(action.res.arr.BACKLOG).forEach(((key) => {
            temp.backlogProjectActions[key] = { ...temp.backlogProjectActions[key], orderIndex: action.res.arr.BACKLOG[key] }
          }))
        }
        if(action.res.column.includes("FINISHED")) {
          Object.keys(action.res.arr.FINISHED).forEach(((key) => {
            temp.plannedProjectActions[key] = { ...temp.plannedProjectActions[key], orderIndex: action.res.arr.FINISHED[key] }
          }))
        }
        if(action.res.column.includes("PLANNED")) {
          Object.keys(action.res.arr.PLANNED).forEach(((key) => {
            temp.plannedProjectActions[key] = { ...temp.plannedProjectActions[key], orderIndex: action.res.arr.PLANNED[key] }
          }))
        }
      }

      return temp
    case "projectActions/removePlanned":
      return {
        ...state,
        plannedProjectActions: removeFromMap(state.plannedProjectActions, action.res),
        unplannedProjectActions: addToMap(state.unplannedProjectActions, action.res),
      }
    case "projectActions/removeAllPlanned/BEGIN":
      return { ...state, unplanning: true }
    case "projectActions/removeAllPlanned":
      var arrLen = action.res ? action.res.length : false
      if (arrLen) {
        for (let i = 0; i < arrLen; i++) {
          temp.plannedProjectActions = removeFromMap(temp.plannedProjectActions, action.res[i])
          temp.unplannedProjectActions = addToMap(temp.unplannedProjectActions, action.res[i])
        }
      }
      temp.unplanning = false
      return {
        ...temp,
      }
    case "scrumView/removeColumn":
      if(action.res.parent === 'TODO'){
        action.res.res.action_ids.forEach((id) => {
          temp.unplannedProjectActions[id] = {...temp.unplannedProjectActions[id] , scrumColumnId: null}
        })
      } else if (action.res.parent === 'BACKLOG'){
        action.res.res.action_ids.forEach((id) => {
          temp.backlogProjectActions[id] = {...temp.backlogProjectActions[id] , scrumColumnId: null}
        })
      } else if (action.res.parent === 'FINISHED' || action.res.parent === 'PLANNED') {
        action.res.res.action_ids.forEach((id) => {
          temp.plannedProjectActions[id] = {...temp.plannedProjectActions[id] , scrumColumnId: null}
        })
      }
      return {
        ...temp
      }
    case "projects/edit":
      // Update actions from data contained in project
      tempProject = { ...action.res }
      delete tempProject.actions
      delete tempProject.actionConnections
      // Delete old unplanned actions
      temp.unplannedProjectActions = { ...temp.unplannedProjectActions }
      for (const id in temp.unplannedProjectActions) {
        if (temp.unplannedProjectActions[id].projectId === tempProject.id) {
          delete temp.unplannedProjectActions[id]
        }
      }
      // Delete old planned actions
      temp.plannedProjectActions = { ...temp.plannedProjectActions }
      for (const id in temp.plannedProjectActions) {
        if (temp.plannedProjectActions[id].projectId === tempProject.id) {
          delete temp.plannedProjectActions[id]
        }
      }
      // Add new actions
      for (const act of action.res.actions) {
        if (act.start === null) {
          temp.unplannedProjectActions[act.id] = {
            ...act,
          }
        } else {
          temp.plannedProjectActions[act.id] = {
            ...act,
          }
        }
      }
      return temp
    case "projects/create":
      // Create actions from data contained in projects
      for (const project of action.res) {
        let tempProject = { ...project }
        delete tempProject.actions
        delete tempProject.actionConnections

        for (const act of project.actions) {
          if (act.start === null) {
            temp.unplannedProjectActions[act.id] = {
              ...act,
            }
          } else {
            temp.plannedProjectActions[act.id] = {
              ...act,
            }
          }
        }
      }
      return temp
    // Remove actions when the project is deleted
    case "projects/remove":

      temp.plannedProjectActions = {}
      Object.values(state.plannedProjectActions).forEach((plannedAction) => {
        if (plannedAction.projectId !== action.res.id) {
          temp.plannedProjectActions[plannedAction.id] = plannedAction
        }
      })
      temp.unplannedProjectActions = {}
      Object.values(state.unplannedProjectActions).forEach((unplannedAction) => {
        if (unplannedAction.projectId !== action.res.id) {
          temp.unplannedProjectActions[unplannedAction.id] = unplannedAction
        }
      })
      temp.backlogProjectActions = {}
      Object.values(state.backlogProjectActions).forEach((unplannedAction) => {
        if (unplannedAction.projectId !== action.res.id) {
          temp.backlogProjectActions[unplannedAction.id] = unplannedAction
        }
      })
      return temp
    case "actions/lockAll":
      let lockedIds = Object.values(action.res.ids)
      lockedIds.forEach((id) => {
        temp.plannedProjectActions[id] = { ...temp.plannedProjectActions[id], movable: false }
      })
      return temp
    case "actions/unlockAll":
      let unlockedIds = Object.values(action.res.ids)
      unlockedIds.forEach((id) => {
        temp.plannedProjectActions[id] = { ...temp.plannedProjectActions[id], movable: true }
      })
      return temp
    case "actions/deleteEverything":
      return temp
    case "projects/lockProject":
      let lockedIdsProject = Object.values(action.res.ids)
      lockedIdsProject.forEach((id) => {
        temp.plannedProjectActions[id] = { ...temp.plannedProjectActions[id], movable: false }
      })
      return temp
    case "projects/unlockProject":
      let unlockedIdsProject = Object.values(action.res.ids)
      unlockedIdsProject.forEach((id) => {
        temp.plannedProjectActions[id] = { ...temp.plannedProjectActions[id], movable: true }
      })
      return temp

    case "projects/unplan":
      let unplannedIds = Object.values(action.res.actions)
      unplannedIds.forEach((id) => {
        const chosenAction = temp.plannedProjectActions[id]
        temp.plannedProjectActions = removeFromMap(temp.plannedProjectActions, chosenAction)
        temp.unplannedProjectActions = addToMap(temp.unplannedProjectActions, chosenAction)
      })
      return temp
    case "actions/projectsInIAPInterval":
      return { ...state, planable: action.res.placeable_actions }
    case "actions/createSingleTask":
      return {
        ...state,
        unplannedProjectActions: addToMap(state.unplannedProjectActions, action.res.project.actions[0]),
      }
    case "teamStatus/includeToday":
      let lockIds = action.res.actions
      lockIds &&
        lockIds.forEach((id) => {
          temp.plannedProjectActions[id] = { ...temp.plannedProjectActions[id], movable: false }
        })
      return temp
    case "auth/logout":
      return initialState
    case "projectActions/filterUnplanned":
      return temp
    case "standardActions/plan": {
      return {
        ...state,
        plannedProjectActions: {
          ...state.plannedProjectActions,
          [action.res.result.action.id]: action.res.result.action,
        },
      };
    }
    default:
      return state
  }
}

export const fetchUnplanned = asyncAction("projectActions/fetchUnplanned", (dispatch) => {
  return request("GET", "/actions/unplanned").then((res) => res.items)
})

export const fetchPlanned = asyncAction("projectActions/fetchPlanned", (dispatch, forLoading) => {
  const state = window.store.getState()
  let [after, before] = getRange(
    state.visual.scheduleMondayDate,
    state.visual.scheduleWithWeekend,
    state.visual.scheduleWithWeek
  )

  // Fetch enough actions for the project view
  // TODO: remove this redundant max check when absolutely certain
  before = DateTime.max(DateTime.fromISO(state.visual.scheduleMondayDate).plus({ day: 7 }), DateTime.fromISO(before))
    .toUTC()
    .toISO()
  return request("GET", `/actions/planned?after=${after}&before=${before}`).then((res) => {
    return res.items
  })
})

export const editPlanned = asyncAction("projectActions/editPlanned", (dispatch, id, patch) => {
  return request("PATCH", `/action/${id}`, { patch }).then(({ action }) => {
    dispatch(projects.updateProject([action]));
    dispatch(updateProjectActions([action]));
    return action;
  });
})

export const move = asyncAction("projectActions/move", (dispatch, id, patch) => {
  return request("PATCH", `/action/${id}`, { patch }).then(({ action }) => {
    dispatch(projects.updateProject([action]))
    return action
  })
})

export const setMovable = asyncAction("projectActions/setMovable", (dispatch, id, movable) => {
  return request("PATCH", `/action/${id}`, { patch: { movable } }).then((res) => {
    dispatch(projects.updateProject([res.action]))
    return res.action
  })
})

export const editUnplanned = asyncAction("projectActions/editUnplanned", (dispatch, id, patch) => {
  return request("PATCH", `/action/${id}`, { patch }).then(({ action }) => {
    dispatch(projects.updateProject([action]));
    dispatch(updateProjectActions([action]));
    return action;
  });
})

export const updateProjectActions = (newActions) => {
  return { type: "projectActions/update", res: newActions };
};

export const removePlanned = asyncAction("projectActions/removePlanned", (dispatch, id) => {
  return request("DELETE", `/action/${id}`).then((res) => {
    dispatch(projects.updateProject([res.action]))
    return res.action
  })
})

export const removeAllPlanned = asyncAction("projectActions/removeAllPlanned", (dispatch) => {
  return request("GET", "/actions/bulk-unplan").then((res) => {
    dispatch(projects.updateProject(res.items))
    return res.items
  })
})

export const lockAll = asyncAction("actions/lockAll", (dispatch) => {
  return request("POST", "/actions/lock-all").then((res) => {
    dispatch(projects.updateProjectMovable(res.ids, false))
    return res
  })
})

export const unlockAll = asyncAction("actions/unlockAll", (dispatch) => {
  return request("POST", "/actions/unlock-all").then((res) => {
    dispatch(projects.updateProjectMovable(res.ids, true))
    return res
  })
})
export const createSingleAction = asyncAction("actions/createSingleTask", (dispatch, input) => {
  return request("POST", "/action/single-action", { input }).then((res) => {
    //dispatch(projects.updateProjectMovable(res.ids, true))
    return res
  })
})

export const projectsInIAPInterval = asyncAction("actions/projectsInIAPInterval", (dispatch, dateFrom, dateTo) => {
  return request("POST", "/iap/projects-in-period", {
    fromDate: dateFrom, //dateFrom.set({ hour: 0, minute: 0, second: 0 }).toFormat("yyyy-MM-dd HH:mm:ssZZ"),
    toDate: dateTo, //dateTo.set({ hour: 23, minute: 23, second: 23 }).toFormat("yyyy-MM-dd HH:mm:ssZZ"),
  }).then((res) => {
    return res
  })
})

// ONLY FOR TEST DATA
export const deleteEverything = asyncAction("actions/deleteEverything", (dispatch) => {
  return request("DELETE", `/actions/delete-everything`).then((res) => {
    // fetching the updated data which will now be empty
    dispatch(loading.fetchAll())
    return res
  })
})

export const reOrderColumns = asyncAction("projectActions/reOrderColumns", (dispatch, res, arr , column) => {
  return request("PATCH", '/action/sort-by-time', { patch: res }).then(() => {
    return {arr, column}
  })
})

export const setLocation = asyncAction("projectActions/setLocation", (dispatch, id, location) => {
  return request("PATCH", `/action/${id}`, { patch: { onSite: location } }).then((res) => {
    dispatch(projects.updateProject([res.action]))
    return res.action
  })
})

export const setLocationUnplanned = asyncAction("projectActions/setLocationUnplanned", (dispatch, id, location) => {
  return request("PATCH", `/action/${id}`, { patch: { onSite: location } }).then((res) => {
    dispatch(projects.updateProject([res.action]))
    return res.action
  })
})

export function filterUnplanned(byGroup, byDeadline, groups, scrum, start, end) {
  return {
    type: "projectActions/filterUnplanned",
    filterSettings: {
      competenceGroupFilter: groups,
      deadlineStart: start,
      deadlineEnd: end,
      isCompetenceGroupFilter: byGroup,
      isDeadlineFilter: byDeadline,
      scrumViewOrder: scrum
    },
  }
}

export const internalOrderChange = asyncAction(
  "projectActions/internalOrderChange",
  (dispatch, id, oldIndex, newIndex, newStage) => {
    return request("PATCH", `/action/internal-order-change/${id}`, {
      patch: { oldIndex, newIndex, newStage },
    })
      .then(() => {
        return { }
      })
  }
)

export const columnOrder = (arr , column) => {
  return {type: "projectActions/columnOrder", arr, column}
}

export const getBookedFreeAssets = asyncAction("projectActions/getBookedFreeAssets", (dispatch, id, patch) => {
  return request("PATCH", `/action/asset-information/${id}`, {
    patch: {
      start: patch.start,
      setuptime: patch.setuptime,
      perSampleDuration: patch.perSampleDuration,
    }
  }).then((res) => {
    return res;
  });
})

export const planStandardAction = asyncAction("standardActions/plan", (dispatch, id, patch) => {
  return request("PATCH", `/action/plan-standard-action/${id}`, { patch }).then((res) => {
    return res
  })
})
