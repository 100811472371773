import React, { useMemo, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Popup } from 'components'; // Adjust the import path as necessary
import { integrationSheets } from 'state_management'; // Adjust the import path as necessary

export default function ErrorPopup({ showErrors, errors, setShowErrors }) {

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(integrationSheets.fetchErrors())
  }, [dispatch]);
  // Memoize grouped and sorted errors to optimize performance
  const groupedErrors = useMemo(() => {
    // Group errors by filename and sheetName, and track latest timestamp
    const groups = Object.values(
      errors.reduce((acc, error) => {
        const key = `${error.filename}||${error.sheetName}`;
        if (!acc[key]) {
          acc[key] = {
            filename: error.filename,
            sheetName: error.sheetName,
            errors: [],
            latestTimestamp: new Date(error.createdAt),
          };
        }
        acc[key].errors.push(error);

        // Update latest timestamp if this error is newer
        const errorDate = new Date(error.createdAt);
        if (errorDate > acc[key].latestTimestamp) {
          acc[key].latestTimestamp = errorDate;
        }

        return acc;
      }, {})
    );

    // Sort groups by latestTimestamp descending
    groups.sort((a, b) => b.latestTimestamp - a.latestTimestamp);

    return groups;
  }, [errors]);
  if (!showErrors || errors === null) return null;

  if (errors.length === 0) {
    return (
      <Popup onCancel={() => setShowErrors(false)} title="Last Error Log">
        <p>No errors to display.</p>
      </Popup>
    );
  }


  return (
    <Popup onCancel={() => setShowErrors(false)} title="Last Error Log">
      <div className="error-logs">
        {groupedErrors.map((group) => (
          <div
            key={`${group.filename}-${group.sheetName}`}
            className="error-group"
          >
            <h3>
              Filename: {group.filename} | Sheet Name: {group.sheetName}
            </h3>
            <table className="error-table">
              <thead>
                <tr>
                  <th scope="col">Timestamp</th>
                  <th scope="col">Row</th>
                  <th scope="col">Message</th>
                </tr>
              </thead>
              <tbody>
                {group.errors
                  .sort(
                    (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
                  ) // Optional: Sort errors within group by timestamp
                  .map((error) => (
                    <tr key={error.id}>
                      <td>
                        {new Date(error.createdAt).toLocaleString()}
                      </td>
                      <td>{error.row}</td>
                      <td>{error.message}</td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
        ))}
      </div>
    </Popup>
  );
}

