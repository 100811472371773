import React, { useState, useEffect, useMemo, useCallback } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useNavigate, useParams } from "react-router-dom"
import { useTable, useSortBy } from "react-table"
import * as XLSX from "xlsx"

import { FiArrowLeft } from "react-icons/fi"
import { FaSort, FaSortUp, FaSortDown } from "react-icons/fa"
import { RiDeleteBinLine } from "react-icons/ri"
import Button from "react-bootstrap/Button"
import "./index.scss"

import { integrationSheets, message } from "state_management"
import { Loading } from "components"

export default function DataGridLayout() {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { sheetId } = useParams()

  const isLoading = useSelector((state) => state.integrationSheets.isLoadingConversionSheet)
  const conversionSheets = useSelector((state) => state.integrationSheets.conversionSheets)
  const conversionSheetEdited = useSelector((state) => state.integrationSheets.editedConversionSheet)
  const limsSheets = useSelector((state) => state.integrationSheets.limsSheets)
  const sapSheets = useSelector((state) => state.integrationSheets.sapSheets)

  const [newRows, setNewRows] = useState([]);
  const [sorting, setSorting] = useState([])
  const [invalidCells, setInvalidCells] = useState(new Set());
  const [confirmGoBack, setConfirmGoBack] = useState(false);

  const currentSheet = useMemo(() => conversionSheets[sheetId] || {}, [conversionSheets, sheetId])
  const shouldFetch = conversionSheets[sheetId] === undefined
  const currentSheetType = limsSheets?.find((sheet) => sheet.id === sheetId)
    ? "Lims"
    : sapSheets?.find((sheet) => sheet.id === sheetId)
    ? "Sap"
    : ""


  const [useBusinessDays, setUseBusinessDays] = useState(currentSheet.useBusinessDays)

  useEffect(() => {
    if (currentSheet.useBusinessDays !== undefined) {
      setUseBusinessDays(currentSheet.useBusinessDays);
    }
  }, [currentSheet]);

  // A Set for holding column headers which should contain numeric values
  const numericHeaders = new Set(["leadTimeToLatestArrival", "leadTimeToDeadline", "leadTimeToEarliestStart"])
  const requiredHeaders = new Set(["limsAnalysisName", "plannertechAnalysisName", "leadTimeToDeadline", "projectTemplateName"])

  // Helper function to sanitize numeric input
  const sanitizeNumericInput = (value) => {
    if (typeof value !== 'string') return value
    // Replace comma with point and remove any non-numeric characters except the point
    return value.replace(',', '.').replace(/[^\d.]/g, '')
  }

  const sanitizePastedText = (text) => {
    return text.replace(/"([^"]*)"/g, (match, p1) => {
      const sanitizedInner = p1.replace(/[\n\r]/g, ' ');
      return `"${sanitizedInner}"`;
    });
  };

  useEffect(() => {
    if (shouldFetch) {
      if (currentSheetType === "Lims") {
        dispatch(integrationSheets.fetchLimsConversionSheet(sheetId)).then((res) => {
          dispatch(integrationSheets.setConversionSheetEdited({ ...res }))
        })
      } else if (currentSheetType === "Sap") {
        dispatch(integrationSheets.fetchSapConversionSheet(sheetId)).then((res) => {
          dispatch(integrationSheets.setConversionSheetEdited({ ...res }))
        })
      } else {
        dispatch(integrationSheets.fetchLimsSheets())
        dispatch(integrationSheets.fetchSapSheets())
      }
    } else {
      dispatch(integrationSheets.setConversionSheetEdited({ ...currentSheet }))
    }
    setUseBusinessDays(currentSheet.useBusinessDays)
  }, [dispatch, sheetId, currentSheetType, shouldFetch, currentSheet])


  const isSheetEdited = useMemo(() => {
    if (!conversionSheetEdited) return false; // If there's no edited version yet, assume no changes

    return (
      JSON.stringify(currentSheet.rows) !== JSON.stringify(conversionSheetEdited.rows) ||
        newRows.length > 0 ||
        currentSheet.useBusinessDays !== useBusinessDays
    );
  }, [currentSheet, conversionSheetEdited, newRows, useBusinessDays]);


  // Define a handler to delete a row
  const handleDeleteRow = useCallback(
    (rowIndex) => {
      const newRows = [...conversionSheetEdited.rows]
      newRows.splice(rowIndex, 1)
      dispatch(integrationSheets.setConversionSheetEdited({ ...conversionSheetEdited, rows: newRows }))

      // Maintain current sorting
      setSorting([...sorting])
    },
    [conversionSheetEdited, dispatch, sorting]
  )

  // Transforming our headers into a format that react-table understands.
  const columns = useMemo(() => {
    let baseColumns =
      conversionSheetEdited?.headers.map((header) => ({
        Header: header.name,
        bold: header.bold,
        accessor: header.key,
      })) || []

    // Add delete column with a class to hide its header
    const deleteColumn = {
      id: "delete",
      Header: () => <div style={{ display: "none" }}></div>, // The header will be invisible
      accessor: "deleteAction", // Unique accessor for the delete action
      Cell: ({ row }) => (
        <div className="delete-cell">
          {/* Prevent tabbing to this button */}
          <Button title="Delete this row" variant="danger" onClick={() => handleDeleteRow(row.index)} tabIndex={-1}>
            <RiDeleteBinLine />
          </Button>
        </div>
      ),
    }

    return [...baseColumns, deleteColumn]
  }, [conversionSheetEdited, handleDeleteRow])

  const data = useMemo(() => conversionSheetEdited?.rows || [], [conversionSheetEdited?.rows])

  const {
    getTableProps, // Returns prop getters for the table element.
    getTableBodyProps, // Returns prop getters for the table body element.
    headerGroups, // An array of normalized header groups.
    rows: tableRows, // Array of row objects from our data.
    prepareRow, // Prepares a row (object) for rendering.
    state: { sortBy }, // Get the current sorting state from React Table
  } = useTable({ columns, data, initialState: { sortBy: sorting } }, useSortBy)

  // Update local sorting state when React Table sorting changes
  useEffect(() => {
    setSorting(sortBy)
  }, [sortBy])

  if (isLoading) {
    return <Loading title="Loading sheet" />
  }

  // This function handles keydown events for table navigation.
  // It uses the DOM to find the current cell and determine the target cell based on the key pressed.
  // This is useful for when the table is sorted because the indices stay the same
  const handleKeyDown = (e) => {
    const key = e.key
    if (!["Enter", "ArrowUp", "ArrowDown", "ArrowLeft", "ArrowRight"].includes(key)) {
      return // Exit if the key is not handled
    }

    e.preventDefault()

    // Find the cell that triggered the event
    const currentCell = e.target.closest("td")
    if (!currentCell) {
      return // Current cell not found
    }

    const columnIndex = Array.from(currentCell.parentElement.children).indexOf(currentCell)

    let targetCell
    switch (key) {
      case "Enter":
      case "ArrowDown":
        targetCell = e.shiftKey
          ? currentCell.parentElement.previousElementSibling?.children[columnIndex]
          : currentCell.parentElement.nextElementSibling?.children[columnIndex]
        break
      case "ArrowUp":
        targetCell = currentCell.parentElement.previousElementSibling?.children[columnIndex]
        break
      case "ArrowLeft":
        targetCell = currentCell.previousElementSibling
        break
      case "ArrowRight":
        targetCell = currentCell.nextElementSibling
        break
      default:
        return // Exit if the key is not handled
    }

    if (targetCell) {
      // Focus on the contentEditable div within the target cell
      const editableDiv = targetCell.querySelector('div[contenteditable="true"]')
      if (editableDiv) {
        editableDiv.focus()
      }
    }
  }

  // Handle content change in a cell
  const handleCellChange = (e, rowIndex, columnId) => {
    const newInvalidCells = new Set(invalidCells);
    let updatedValue = e.target.innerText.trim() // Trim to ensure it's empty if only whitespaces

    // If the column is numeric, replace comma with point
    if (numericHeaders.has(columnId)) {
      updatedValue = sanitizeNumericInput(updatedValue)
    }

    const updatedRows = [...conversionSheetEdited.rows]
    const cellId = `${rowIndex}-${columnId}`;

    if (
      (requiredHeaders.has(columnId) && updatedValue === "") ||
        (numericHeaders.has(columnId) && Number.isNaN(Number(updatedValue)))
    ) {
      newInvalidCells.add(cellId);
    } else {
      newInvalidCells.delete(cellId);
    }

    setInvalidCells(newInvalidCells);

    if (updatedRows[rowIndex] === undefined) {
      updatedRows[rowIndex] = {}
    } else {
      updatedRows[rowIndex] = { ...updatedRows[rowIndex] }
    }

    if (updatedValue === "" && !requiredHeaders.has(columnId)) {
      delete updatedRows[rowIndex][columnId] // Remove the key if cell is empty
    } else {
      updatedRows[rowIndex][columnId] = numericHeaders.has(columnId) && updatedValue !== "" ? parseFloat(updatedValue) : updatedValue
    }

    dispatch(integrationSheets.setConversionSheetEdited({ ...conversionSheetEdited, rows: updatedRows }))
  }

  // Handle pasting of data into the grid
  const handlePaste = (e, rowIndex, cellIndex) => {
    e.preventDefault();
    const newInvalidCells = new Set(invalidCells);

    let pastedData = e.clipboardData.getData("Text").trim();

    // Check if the table is sorted
    if (sorting.length > 0) {
      const isSingleCellPaste = !pastedData.includes("\n");
      const hasSurroundingQuotes = pastedData.startsWith('"') && pastedData.endsWith('"');

      if (!isSingleCellPaste && !hasSurroundingQuotes) {
        dispatch(message.warning("Please unsort the table before pasting multiple cells."));
        return;
      }
    }

    // Sanitize the pasted data by replacing newlines within quotes
    pastedData = sanitizePastedText(pastedData);

    // Split the sanitized data into rows and cells
    const pastedRows = pastedData.split(/\r?\n/).map(row => row.split("\t"));
    const updatedRows = [...conversionSheetEdited.rows];

    pastedRows.forEach((row, rIdx) => {
      const targetRowIndex = rowIndex + rIdx;
      updatedRows[targetRowIndex] = { ...updatedRows[targetRowIndex] || {} };

      row.forEach((cell, cIdx) => {
        const targetCellIndex = cellIndex + cIdx;
        if (targetCellIndex < conversionSheetEdited.headers.length) {
          let sanitizedCell = cell.trim();

          // Remove surrounding quotes if present
          if (sanitizedCell.startsWith('"') && sanitizedCell.endsWith('"')) {
            sanitizedCell = sanitizedCell.slice(1, -1);
          }

          // Further sanitize numeric input if required
          if (numericHeaders.has(conversionSheetEdited.headers[targetCellIndex].key)) {
            sanitizedCell = sanitizeNumericInput(sanitizedCell);
          }

          const headerKey = conversionSheetEdited.headers[targetCellIndex].key;
          const cellId = `${targetRowIndex}-${headerKey}`;
          const isRequired = requiredHeaders.has(headerKey);
          const isNumeric = numericHeaders.has(headerKey);
          const isInvalid = (isRequired && sanitizedCell === "") || (isNumeric && Number.isNaN(Number(sanitizedCell)));

          isInvalid ? newInvalidCells.add(cellId) : newInvalidCells.delete(cellId);

          if (sanitizedCell === "" && !isRequired) {
            delete updatedRows[targetRowIndex][headerKey];
          } else {
            updatedRows[targetRowIndex][headerKey] = isNumeric && sanitizedCell !== "" ? parseFloat(sanitizedCell) : sanitizedCell;
          }
        }
      });
    });

    setInvalidCells(newInvalidCells);
    dispatch(integrationSheets.setConversionSheetEdited({ ...conversionSheetEdited, rows: updatedRows }));
  };

  const getOnPasteHandler = (rowIndex, columnId) => (e) => {
    const cellIndex = conversionSheetEdited.headers.findIndex((header) => header.key === columnId)
    handlePaste(e, rowIndex, cellIndex)
  }

  // Adds an empty row to the table.
  const handleAddRow = () => {
    setNewRows([...newRows, {}]);
  }

  const handleNewRowCellChange = (e, rowIndex, columnId) => {
    const newInvalidCells = new Set(invalidCells);
    let updatedValue = e.target.innerText.trim();

    if (numericHeaders.has(columnId)) {
      updatedValue = sanitizeNumericInput(updatedValue);
    }

    const updatedNewRows = [...newRows];
    const cellId = `new-${rowIndex}-${columnId}`;

    if (
      (requiredHeaders.has(columnId) && updatedValue === "") ||
        (numericHeaders.has(columnId) && Number.isNaN(Number(updatedValue)))
    ) {
      newInvalidCells.add(cellId);
    } else {
      newInvalidCells.delete(cellId);
    }

    setInvalidCells(newInvalidCells);

    if (updatedNewRows[rowIndex] === undefined) {
      updatedNewRows[rowIndex] = {};
    } else {
      updatedNewRows[rowIndex] = { ...updatedNewRows[rowIndex] };
    }

    if (updatedValue === "" && !requiredHeaders.has(columnId)) {
      delete updatedNewRows[rowIndex][columnId];
    } else {
      updatedNewRows[rowIndex][columnId] = numericHeaders.has(columnId) && updatedValue !== "" ? parseFloat(updatedValue) : updatedValue;
    }

    setNewRows(updatedNewRows);
  }

  const handleDeleteNewRow = (rowIndex) => {
    const updatedNewRows = [...newRows];
    updatedNewRows.splice(rowIndex, 1);
    setNewRows(updatedNewRows);

    // Update invalidCells
    const newInvalidCells = new Set(invalidCells);
    conversionSheetEdited.headers.forEach(header => {
      const cellId = `new-${rowIndex}-${header.key}`;
      newInvalidCells.delete(cellId);
    });
    setInvalidCells(newInvalidCells);
  }

  const handlePasteInNewRows = (e, rowIndex, cellIndex) => {
    e.preventDefault();
    const newInvalidCells = new Set(invalidCells);

    let pastedData = e.clipboardData.getData("Text").trim();

    // Sanitize the pasted data by replacing newlines within quotes
    pastedData = sanitizePastedText(pastedData);

    // Split the sanitized data into rows and cells
    const pastedRows = pastedData.split(/\r?\n/).map(row => row.split("\t"));
    const updatedNewRows = [...newRows];

    pastedRows.forEach((row, rIdx) => {
      const targetRowIndex = rowIndex + rIdx;
      updatedNewRows[targetRowIndex] = { ...updatedNewRows[targetRowIndex] || {} };

      row.forEach((cell, cIdx) => {
        const targetCellIndex = cellIndex + cIdx;
        if (targetCellIndex < conversionSheetEdited.headers.length) {
          let sanitizedCell = cell.trim();

          // Remove surrounding quotes if present
          if (sanitizedCell.startsWith('"') && sanitizedCell.endsWith('"')) {
            sanitizedCell = sanitizedCell.slice(1, -1);
          }

          // Further sanitize numeric input if required
          if (numericHeaders.has(conversionSheetEdited.headers[targetCellIndex].key)) {
            sanitizedCell = sanitizeNumericInput(sanitizedCell);
          }

          const headerKey = conversionSheetEdited.headers[targetCellIndex].key;
          const cellId = `new-${targetRowIndex}-${headerKey}`;
          const isRequired = requiredHeaders.has(headerKey);
          const isNumeric = numericHeaders.has(headerKey);
          const isInvalid = (isRequired && sanitizedCell === "") || (isNumeric && Number.isNaN(Number(sanitizedCell)));

          isInvalid ? newInvalidCells.add(cellId) : newInvalidCells.delete(cellId);

          if (sanitizedCell === "" && !isRequired) {
            delete updatedNewRows[targetRowIndex][headerKey];
          } else {
            updatedNewRows[targetRowIndex][headerKey] = isNumeric && sanitizedCell !== "" ? parseFloat(sanitizedCell) : sanitizedCell;
          }
        }
      });
    });

    setInvalidCells(newInvalidCells);
    setNewRows(updatedNewRows);
  };

  const getNewRowOnPasteHandler = (rowIndex, columnId) => (e) => {
    const cellIndex = conversionSheetEdited.headers.findIndex((header) => header.key === columnId);
    handlePasteInNewRows(e, rowIndex, cellIndex);
  }


  const handleClearTable = () => {
    dispatch(
      integrationSheets.setConversionSheetEdited({
        ...conversionSheetEdited,
        rows: [],
      })
    )
  }

  function validateAndMarkCells(existingRows, newRows, requiredHeaders) {
    const newInvalidCells = new Set();

    // Validate existing rows
    existingRows.forEach((row, rowIndex) => {
      conversionSheetEdited.headers.forEach((header) => {
        const columnId = header.key;
        const value = row[columnId];
        const isRequired = requiredHeaders.has(columnId);
        const isNumeric = numericHeaders.has(columnId);

        const isEmpty = value == null || value.toString().trim() === "";
        const isEmptyOrRequiredNull = isRequired && isEmpty;
        const isNumericNaN = isNumeric && !isEmpty && Number.isNaN(Number(value));

        const cellId = `${rowIndex}-${columnId}`;

        if (isEmptyOrRequiredNull || isNumericNaN) {
          newInvalidCells.add(cellId);
        } else {
          newInvalidCells.delete(cellId);
        }
      });
    });

    // Validate new rows
    newRows.forEach((row, rowIndex) => {
      conversionSheetEdited.headers.forEach((header) => {
        const columnId = header.key;
        const value = row[columnId];
        const isRequired = requiredHeaders.has(columnId);
        const isNumeric = numericHeaders.has(columnId);

        const isEmpty = value == null || value.toString().trim() === "";
        const isEmptyOrRequiredNull = isRequired && isEmpty;
        const isNumericNaN = isNumeric && !isEmpty && Number.isNaN(Number(value));

        const cellId = `new-${rowIndex}-${columnId}`;

        if (isEmptyOrRequiredNull || isNumericNaN) {
          newInvalidCells.add(cellId);
        } else {
          newInvalidCells.delete(cellId);
        }
      });
    });

    setInvalidCells(newInvalidCells);

    return newInvalidCells.size > 0;
  }
  const scrollToAutomaticState = { scrollToAutomatic: true } ;

  const handleSave = () => {
    const invalidCellsPresent = validateAndMarkCells(conversionSheetEdited.rows, newRows, requiredHeaders);
    currentSheet.useBusinessDays = useBusinessDays;

    if (invalidCellsPresent) {
      dispatch(message.warning("Please fill in all required fields"));
      return;
    }

    const allRows = [...conversionSheetEdited.rows, ...newRows];

    const saveAction =
      currentSheetType === "Lims"
        ? integrationSheets.saveLimsConversionSheet(sheetId, allRows, useBusinessDays)
        : currentSheetType === "Sap"
          ? integrationSheets.saveSapConversionSheet(sheetId, allRows, useBusinessDays)
          : null;

    if (saveAction) {
      dispatch(saveAction)
        .then(() => {
          navigate("/setup/integration", { state: scrollToAutomaticState });
          setNewRows([]); // Clear newRows only after successful save
        })
    }
  };



  const handleReset = () => {
    const currentRows = [...currentSheet.rows];
    validateAndMarkCells(currentRows, newRows, requiredHeaders);
    dispatch(integrationSheets.setConversionSheetEdited({ ...currentSheet }));
    setNewRows([]);
  }



  const handleGoBack = () => {
    if (isSheetEdited) {
      if (confirmGoBack) {
        navigate("/setup/integration", { state: scrollToAutomaticState });
      } else {
        dispatch(message.warning("You have unsaved changes. To confirm you want to leave, press the back button again."));
        setConfirmGoBack(true);

        // Reset confirm state after a delay
        setTimeout(() => {
          setConfirmGoBack(false);
        }, 10000);
      }
    } else {
      // No changes, navigate back immediately
      navigate("/setup/integration", { state: scrollToAutomaticState });
    }
  };



  const handleExportExcel = () => {
    // Select the table element
    const table = document.querySelector('.excelGrid');
    if (!table) {
      dispatch(message.error('Table not found'));
      return;
    }

    // Use xlsx to convert the table to a worksheet
    const workbook = XLSX.utils.table_to_book(table, { sheet: 'Sheet1' });

    // Generate a binary string representation of the workbook
    const workbookBinary = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });

    // Create a Blob from the binary string
    const blob = new Blob([workbookBinary], { type: 'application/octet-stream' });

    // Create a temporary link to trigger the download
    const url = URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = 'Conversion Sheet.xlsx'; // Specify the file name
    document.body.appendChild(a);
    a.click();

    // Clean up by removing the temporary link and revoking the object URL
    document.body.removeChild(a);
    URL.revokeObjectURL(url);
  }


  return (
    <div className="DataGridLayout-container">
      <h3>{`Automatic ${currentSheetType} Conversion Sheet`}</h3>
      <div className="data-grid-config-buttons">
        <div className="data-grid-left-button">
          <Button
            variant={isSheetEdited && confirmGoBack ? "danger" : "primary"}
            title={"Go back"}
            onClick={handleGoBack}
          >
            <FiArrowLeft />
          </Button>
        </div>
        <div className="data-grid-center-buttons">
          <Button variant="secondary" onClick={handleReset}>
            Cancel Changes
          </Button>
          <Button onClick={handleSave}>Save</Button>
          <Button variant="success" onClick={handleExportExcel}>
            Export to Excel
          </Button>
          <label className="business-days-label" >
            <input
              type="checkbox"
              checked={useBusinessDays}
              onChange={(e) => setUseBusinessDays(e.target.checked)}
            />
            <span className="label-text" style={{ marginLeft: '-5px' }}>Lead Times in Work Days</span>
          </label>
        </div>
        <div className="data-grid-right-button" style={{ visibility: data.length === 0 ? "hidden" : "" }}>
          <Button variant="danger" onClick={handleClearTable}>
            Clear Table
          </Button>
        </div>
      </div>
      <div className="excelGrid-container">
        <table {...getTableProps()} className="excelGrid">
          <thead>
            {headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  <th
                    {...column.getHeaderProps(column.getSortByToggleProps())}
                    className={column.id === "delete" ? "delete-header" : ""}
                    title={`Click to sort by ${column.Header}`}
                    style={{ cursor: "pointer", fontWeight: column.bold ? "bold" : "normal" }}
                  >
                    <div className="excelGrid-header-content">
                      <span className="header-text">
                        {column.render("Header")}
                      </span>
                      <span className="sort-icon">
                        {column.isSorted ? (
                          column.isSortedDesc ? (
                            <FaSortDown size={24} />
                          ) : (
                              <FaSortUp size={24} />
                            )
                        ) : (
                            <FaSort size={24} />
                          )}
                      </span>
                    </div>
                  </th>
                ))}
              </tr>
            ))}
          </thead>

          <tbody {...getTableBodyProps()}>
            {tableRows.map((row) => {
              prepareRow(row)
              return (
                <MemoizedRow
                  key={row.id}
                  row={row}
                  handleCellChange={handleCellChange}
                  getOnPasteHandler={getOnPasteHandler}
                  handleKeyDown={handleKeyDown}
                  invalidCells={invalidCells}
                />
              )
            })}
          </tbody>
          {/* Add the footer for new rows */}
          <tfoot>
            {newRows.map((row, rowIndex) => (
              <tr key={`new-${rowIndex}`}>
                {conversionSheetEdited.headers.map((header) => {
                  const cellId = `new-${rowIndex}-${header.key}`;
                  const isInvalid = invalidCells.has(cellId);

                  return (
                    <td key={header.key} style={isInvalid ? { borderColor: 'rgba(255, 0, 0, 1)', borderWidth: 2, borderStyle: 'solid' } : {}}>
                      <div
                        contentEditable
                        onBlur={(e) => handleNewRowCellChange(e, rowIndex, header.key)}
                        onPaste={getNewRowOnPasteHandler(rowIndex, header.key)}
                        onKeyDown={handleKeyDown}
                        suppressContentEditableWarning={true}
                        dangerouslySetInnerHTML={{ __html: row[header.key] || '' }}
                        data-columnid={header.key}
                      />
                    </td>
                  )
                })}
                <td className="delete-cell">
                  <Button variant="danger" onClick={() => handleDeleteNewRow(rowIndex)} tabIndex={-1}>
                    <RiDeleteBinLine />
                  </Button>
                </td>
              </tr>
            ))}
          </tfoot>
        </table>

      </div>
      <div className="add-row-btn-container">
        <Button variant="secondary" onClick={handleAddRow}>
          Add Row
        </Button>
      </div>
    </div>
  )
}

const MemoizedCell = React.memo(({ cell, handleCellChange, getOnPasteHandler, handleKeyDown, isInvalid }) => {
  const cellStyle = isInvalid ? { borderColor: 'rgba(255, 0, 0, 1)', borderWidth: 2, borderStyle: 'solid' } : {};

  return (
    <td {...cell.getCellProps()} style={cellStyle}>
      <div
        // key={cell.column.id}
        contentEditable
        onBlur={(e) => handleCellChange(e, cell.row.index, cell.column.id)}
        onPaste={getOnPasteHandler(cell.row.index, cell.column.id)}
        onKeyDown={(e) => handleKeyDown(e)}
        suppressContentEditableWarning={true}
        dangerouslySetInnerHTML={{ __html: cell.value }}
        data-columnid={cell.column.id}
      />
    </td>
  )
})

const MemoizedDeleteCell = React.memo(({ cell }) => {
  return (
    <td {...cell.getCellProps()} className="delete-cell">
      {cell.render("Cell")}
    </td>
  )
})

const MemoizedRow = React.memo(({ row, handleCellChange, getOnPasteHandler, handleKeyDown, invalidCells }) => {
  return (
    <tr {...row.getRowProps()}>
      {row.cells.map((cell) => {
        const cellId = `${row.index}-${cell.column.id}`;
        const isInvalid = invalidCells.has(cellId);

        if (cell.column.id !== "delete") {
          return (
            <MemoizedCell
              key={cell.column.id}
              cell={cell}
              handleCellChange={handleCellChange}
              getOnPasteHandler={getOnPasteHandler}
              handleKeyDown={handleKeyDown}
              isInvalid={isInvalid}
            />
          )
        } else {
          return <MemoizedDeleteCell key={cell.column.id} cell={cell} />
        }
      })}
    </tr>
  )
})
