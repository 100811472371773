import React, { useState, useEffect, useRef } from "react"
import { useDispatch, useSelector } from "react-redux"
import { DateTime } from "luxon"
import { CheckDates } from "utils/scripts/schedule"
import Button from "react-bootstrap/Button"
import { ConfirmDialog } from "components"
import useTeamComponents from "./useTeamComponents"
import { AssetOverbookingButton, Calendar, IncludeTodayButton, RunIAPButton, IgnoreGreyTasksButton } from "./CommonComponents"
import { analyses, projectActions, teamInfo, iap, message, waiting, teamStatus } from "state_management"

import "./index.scss"

// Configures and runs the IAP.
export default function IAP() {
  const [activeConfirm, setActiveConfirm] = useState(false)
  const [showWarning, setShowWarning] = useState(false)
  const [buttonType, setButtonType] = useState({type:"", message:""})

  const dispatch = useDispatch()
  const showProjects = useSelector((state) => state.teamStatus.showProjects)
  const showAnalyses = useSelector((state) => state.teamStatus.showAnalyses)
  const includeToday = useSelector((state) => state.teamStatus.includeToday)
  const IAPconfig = useSelector((state) => state.visual.IAPconfig)
  const hasFetched = useSelector((state) => state.teamInfo.hasFetched)
  const memberTimezone = useSelector((state) => state.auth.memberTimezone)
  // const tasks = useSelector((state) => state.analysisTasks)
  const iapLoading = useSelector((state) => state.waiting.iapLoading)
  const sampleAllocating = useSelector((state) => state.waiting.sampleAllocating)
  const fillInLoading = useSelector((state) => state.waiting.fillInLoading)
  const onClearTimer = useRef(null);
  const sortingParameter = useSelector((state) => state.teamStatus.sortingParameter)

  const currentTime = DateTime.local().setZone(memberTimezone).set({ hour: 12 })

  const isMixed = showProjects && showAnalyses
  const isAnalysis = !showProjects && showAnalyses

  useEffect(() => {
    if (!hasFetched) {
      dispatch(teamInfo.fetch())
    }
  })

  const lastDisabledDay = includeToday
    ? DateTime.local().setZone(memberTimezone).set({ hour: 12 })
    : DateTime.local().setZone(memberTimezone).set({ hour: 12 }).plus({ days: 1 })

  const iapMode = !showProjects ? "analysis" : !showAnalyses ? "project" : IAPconfig.IAPmode

  iapMode === "analysis" && sortingParameter !== "PRIORITY" && dispatch(teamStatus.changeIAPPriority({ sortingParameter: "PRIORITY" }))

  const dateFrom = IAPconfig.timePeriod.from
    ? DateTime.max(lastDisabledDay, DateTime.fromISO(IAPconfig.timePeriod.from, { zone: memberTimezone })).set({
        hour: 12,
      })
    : null
  const dateTo = IAPconfig.timePeriod.to
    ? DateTime.max(lastDisabledDay, DateTime.fromISO(IAPconfig.timePeriod.to, { zone: memberTimezone })).set({
        hour: 12,
      })
    : null

  const { PriorityButtons, IncludeOverdueButton, LockUnlockButtons } = useTeamComponents()

  const IAPOnClick = () => {
    if (onClearTimer.current) onClearTimer.current();

    if (!iapLoading && !sampleAllocating && !fillInLoading) {
      if (dateFrom && dateTo) {
        dispatch(teamStatus.setIAPconfiguration(IAPconfig.timePeriod.from, IAPconfig.timePeriod.to)).then(() => {
          dispatch(waiting.changeIapLoading("Running IAP"))
          dispatch(iap.run(dateFrom, dateTo, iapMode, "robin", "deadline")).finally(() =>
            dispatch(waiting.changeIapLoading(""))
          )
        })
      } else {
        dispatch(message.info("Please fill out required fields"))
      }
    }
  }

  const FillInOnClick = () => {
    if (!iapLoading || !sampleAllocating || !fillInLoading) {
      dispatch(teamStatus.setIAPconfiguration(IAPconfig.timePeriod.from, IAPconfig.timePeriod.to)).then(() => {
        dispatch(waiting.changeFillInLoading("Filling in samples"))
        dispatch(iap.runFillInSamples(dateFrom, dateTo)).finally(() => dispatch(waiting.changeFillInLoading("")))
      })
    }
  }

  const handleButtonClick = (buttonType) => {
    if (!dateFrom || !dateTo) {
     dispatch(message.warning("Please select valid dates"))
      return
    }

    if (iapMode !== "project" && includeToday && CheckDates(currentTime, dateFrom, dateTo)) {
      setButtonType({type: buttonType, message:`Warning ${buttonType} and Include Today - tasks planned earlier today can also ${buttonType === "IAP" ? "be moved unless they are locked!" : "get new samples allocated!"}`})
      setShowWarning(true)
    } else {
      // Execute the old actions
      if (buttonType === "IAP") {
        IAPOnClick()
      } else if (buttonType === "Fill In") {
        FillInOnClick()
      }
    }
  }

  const handleConfirm = (buttonType) => {
    if (buttonType === "IAP") {
      IAPOnClick()
    } else if (buttonType === "Fill In") {
      FillInOnClick()
    }
    setShowWarning(false)
  }

  // Hide if projects and analyses are hidden!
  if (!showProjects && !showAnalyses) {
    return null
  }

  return (
    <div className="IapMenu">
      <h2>IAP</h2>
      {iapMode === "project" && (
        <div>
          <h3>Priority</h3>
          {PriorityButtons}
        </div>
      )}

      <h3>Time Period</h3>
      <div className="LeftMenu-daypickers">
        <div className="Calendar">
          <Calendar dateFrom={dateFrom} dateTo={dateTo} lastDisabledDay={lastDisabledDay} IAPconfig={IAPconfig} onClearTimer={onClearTimer} />
        </div>
        <IncludeTodayButton />

        <AssetOverbookingButton />
        <IgnoreGreyTasksButton />
        {IncludeOverdueButton}
        {/*{IAPconfig.IAPmode === "analysis" && <IgnoreOverbooking />}*/}
      </div>
      <div className="iap-bottom-btns">
        {activeConfirm && (
          <ConfirmDialog
            onCancel={() => setActiveConfirm(false)}
            onConfirm={() => {
              if (IAPconfig.IAPmode === "project") {
                dispatch(projectActions.removeAllPlanned())
              } else if (IAPconfig.IAPmode === "analysis") {
                dispatch(analyses.removeAllPlanned())
              }
              setActiveConfirm(false)
            }}
          >
            <h4>
              Are you sure you want to unplan all{" "}
              {IAPconfig.IAPmode === "analysis" ? "analysis tasks" : IAPconfig.IAPmode === "project" ? "project actions" : ""}?
            </h4>
          </ConfirmDialog>
        )}
        <RunIAPButton handleClick={() => handleButtonClick("IAP")} />

        {showWarning && (
          <ConfirmDialog submitName={"Continue"} onConfirm={() => handleConfirm(buttonType.type)} onCancel={() => setShowWarning(false)}>
            <h4>{buttonType.message}</h4>
          </ConfirmDialog>
        )}

        {(isAnalysis || (isMixed && iapMode === "analysis")) && (
          <Button onClick={() => handleButtonClick("Fill In")} className="run-iap-btn">
            {fillInLoading ? " Processing..." : "Fill In"}
          </Button>
        )}

        <hr className="Left-Menu-line" />
        <Button className="unplan-iap-btn" variant="danger" onClick={() => setActiveConfirm(true)}>
          Unplan {IAPconfig.IAPmode === "analysis" ? "tasks" : IAPconfig.IAPmode === "project" ? "actions" : ""}
        </Button>
      </div>
      {LockUnlockButtons}
    </div>
  )
}
