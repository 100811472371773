import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { useDispatch } from 'react-redux'

import { ConfigContainer } from 'components'
import { Title, Container, Text, Select } from 'components/Form'
import { librarian, message } from 'state_management'


export default function ChatbotConfig({ customerAdminView = false, repositories, chatbotData, onCancel }) {
  const dispatch = useDispatch()

  const [name, setName] = useState(chatbotData ? chatbotData.name : "")
  const [systemPrompt, setSystemPrompt] = useState(chatbotData ? chatbotData.systemPrompt : "")
  const [selectedRepositories, setSelectedRepositories] = useState(chatbotData ? chatbotData.repositoryIds : [])
  const role = useSelector((state) => state.auth.role)

  const onSubmit = () => {
    const input = { name, systemPrompt, repositoryIds: selectedRepositories }
    if (chatbotData) {
      if (role !== "planner") {
        return dispatch(librarian.editAdminChatbot(chatbotData.id, input)).then(onCancel)
      } else {
        dispatch(librarian.editChatbot(chatbotData.id, input)).then(onCancel)
      }
    } else {
      if (role !== "planner") {
        if (customerAdminView) {
          return dispatch(librarian.createGlobalChatbot(input)).then(onCancel)
        } else {
          return dispatch(librarian.createAdminChatbot(input)).then(onCancel)
        }
      } else {
        dispatch(librarian.createChatbot(input)).then(onCancel)
      }
    }
  }
  return (
    <ConfigContainer
      title={chatbotData ? "Edit chatbot" : "Create chatbot"}
      submitHandler={onSubmit}
      cancelHandler={onCancel}
      keyWordForAllowedDeletion="DELETE"
      deleteHandler={chatbotData ? (allowDeletion) => {
        if (allowDeletion === "DELETE") {
          if (chatbotData) {
            dispatch(librarian.deleteChatbot(chatbotData.id)).then(onCancel)
          }
        } else {
          dispatch(message.info("You did not spell DELETE properly"))
        }
      } : undefined}
    >
      <Container>
        <Title>Chatbot Name</Title>
        <Text value={name} required onChange={setName} />
      </Container>
      <Container>
        <Title>System prompt</Title>
        <Text large={true} value={systemPrompt} required onChange={setSystemPrompt} />
      </Container>
      <Container>
        <Title>Repositories</Title>
        <Select
          name="Repositories"
          collection={repositories}
          value={selectedRepositories}
          onChange={setSelectedRepositories}
          menuPosition="fixed"
        />
      </Container>
    </ConfigContainer>
  )
}
