import React from "react"
import { useSelector } from "react-redux"
import { useParams } from "react-router-dom"

import ScheduleScreenContent from "./ScheduleScreenContent"
import { ScheduleButtons, RightMenu } from "components"

export default function SingleMemberScheduleScreen({ view }) {
  const { memberId } = useParams()
  const member = useSelector((state) => state.members[memberId])
  const rightMenuVisible = useSelector((state) => state.visual.rightMenuVisible)
  const showRightMenu = useSelector((state) => state.teamStatus.showProjects || state.teamStatus.showAnalyses)

  return (
    <div className="ScheduleScreen">
      {showRightMenu && <RightMenu open={rightMenuVisible} />}
      <ScheduleButtons type="single-member" moveRight={rightMenuVisible && showRightMenu} member={member} view={view} />
      <ScheduleScreenContent moveRight={rightMenuVisible && showRightMenu} singleMember members={[member]} view={view} />

    </div>
  )
}
