import React, { useState, useEffect } from "react"
import { useSelector, useDispatch } from "react-redux"
import { useNavigate, useLocation } from "react-router-dom"

import "./index.scss"

import Button from "react-bootstrap/Button"
// import { UpdateIndicator } from "components"
import { FiArrowLeft, FiHome, FiLogOut, FiCopy } from "react-icons/fi"
import { BsFillChatDotsFill } from "react-icons/bs"
import { FaKey } from "react-icons/fa";

import { auth, message, repositories, token, visual, librarian } from "state_management"
import { Popup } from "components"
import { Title, Container, Text } from "components/Form"

// Buttons used on various schedule screens.
export default function ScheduleButtonsAdmin() {
  const dispatch = useDispatch()
  const location = useLocation()
  const navigate = useNavigate()
  const role = useSelector((state) => state.auth.role)
  const isCustomerAdmin = role === "customer_admin"
  const hasApiToken = useSelector((state) => state.auth.hasApiToken)
  const isFrontPage = isCustomerAdmin ? location.pathname === "/customers" : location.pathname === "/locations"
  const [activePopupApi, setactivePopupApi] = useState(false)
  const [showPrompt, setShowPrompt] = useState(false)
  const [showToken, setShowToken] = useState(false)
  const tokenString = useSelector((state) => state.token.token)

  const initialGeneralPrefix = useSelector((state) => state.librarian.generalPrefix)
  const initialRelevantDocumentation = useSelector((state) => state.librarian.relevantDocumentation)
  const initialLastQuestion = useSelector((state) => state.librarian.lastQuestion)
  const [generalPrefix, setGeneralPrefix] = useState(null)
  const [relevantDocumentation, setRelevantDocumentation] = useState(null)
  const [lastQuestion, setLastQuestion] = useState(null)

  useEffect(() => {
    if (isCustomerAdmin) {
      dispatch(librarian.fetchConfig())
    }
  }, [isCustomerAdmin, dispatch])

  useEffect(() => {
    if (initialGeneralPrefix && initialRelevantDocumentation && initialLastQuestion) {
      setGeneralPrefix(initialGeneralPrefix);
      setRelevantDocumentation(initialRelevantDocumentation);
      setLastQuestion(initialLastQuestion);
    }
  }, [initialRelevantDocumentation, initialLastQuestion , initialGeneralPrefix]);


  const handleGoBack = () => {
    updateRepository()
    navigate(-1)
  }

  const handleCopy = () => {
    navigator.clipboard
      .writeText(tokenString)
      .then(() => {
        dispatch(message.success("Copied to clipboard"))
      })
      .catch(() => {
        dispatch(message.error("Could not copy to clipboard"))
      })
  }

  const handleSubmit = () => {
    if (generalPrefix !== initialGeneralPrefix) {
      dispatch(librarian.setGeneralPrefix({ prefix: generalPrefix }));
    }

    if (relevantDocumentation !== initialRelevantDocumentation) {
      dispatch(librarian.setRelevantDocumentation({ string: relevantDocumentation }));
    }

    if (lastQuestion !== initialLastQuestion) {
      dispatch(librarian.setLastQuestion({ string: lastQuestion }));
    }

    setShowPrompt(false);
  }

  const updateRepository = () => {
    const isRepositoryView =
      location.pathname.includes("/customers/global-repositories/") ||
      location.pathname.includes("/locations/repository/")
    if (isRepositoryView) {
      const repositoryId = location.pathname.split("/")[3]
      dispatch(repositories.fetchRepository(repositoryId))
    }
  }

  return (
    <>
      {showPrompt && isCustomerAdmin && (
        <Popup
          onCancel={() => {
            setShowPrompt(false)
          }}
          title="Chatbot System Prompt"
        >
          <Container>
            <Title>General Prefix</Title>
            <Text
              value={generalPrefix}
              large={true}
              required
              onChange={(value) => {
                setGeneralPrefix(value)
              }}
              rows={5}
            />
          </Container>
          <Container>
            <Title>Relevant Documentation</Title>
            <Text
              value={relevantDocumentation}
              large={true}
              required
              onChange={(value) => {
                setRelevantDocumentation(value)
              }}
              rows={5}
            />
          </Container>
          <Container>
            <Title>Last Question Hint</Title>
            <Text
              value={lastQuestion}
              large={true}
              required
              onChange={(value) => {
                setLastQuestion(value)
              }}
              rows={5}
            />
          </Container>
          <Button
            variant="primary"
            onClick={handleSubmit}
          >
            Save
          </Button>
          <Button
            style={{ marginLeft: "0.75em" }}
            variant="danger"
            onClick={() => {
              setGeneralPrefix(initialGeneralPrefix)
              setRelevantDocumentation(initialRelevantDocumentation)
              setLastQuestion(initialLastQuestion)
              setShowPrompt(false)
            }}
          >
            Cancel
          </Button>
        </Popup>
      )}
      {activePopupApi && isCustomerAdmin && (
        <Popup
         onCancel={() => {
          if (!showToken) {
            setactivePopupApi(false)
          }
         }}
         title="Customer Admin API key for Global Repositories"
        >
          {hasApiToken ? (
            <Button
            className="token-button"
            variant="danger"
            onClick={() => {
              dispatch(token.deleteCustomerAdminToken())
              dispatch(auth.setHasAPIToken(false))
            }}
            >
              Delete API key
          </Button>
          ) : (
            <Button
              className="token-button"
              variant="success"
              onClick={() => {
                dispatch(token.createCustomerAdminToken())
                dispatch(auth.setHasAPIToken(true))
                setShowToken(true)
              }}
              >
                Create API key
            </Button>
          )}
        </Popup>
      )}
      <div className="ScheduleButtons admin">
        <div className="leftmenu-button" style={{ visibility: !isFrontPage ? "visible" : "hidden" }}>
          <Button variant="primary" title={"Go back"} onClick={handleGoBack}>
            <FiArrowLeft />
          </Button>
        </div>

        <div className="center-buttons">
          <Button
            variant="primary"
            title={"Home"}
            onClick={() => {
              updateRepository()
              navigate(`/${isCustomerAdmin ? "customers" : "locations"}`)
            }}
          >
            <FiHome/>
          </Button>
          {isCustomerAdmin && isFrontPage && (
            <div
              className="globalmessage-button"
            >
              <Button
                variant="primary"
                title="Edit Global Message"
                onClick={() => {
                  dispatch(visual.showGlobalMessageConfig(true))
                }}
              >
                <BsFillChatDotsFill/>
              </Button>
            </div>
          )}
          {isCustomerAdmin && (
            <Button
              variant="primary"
              title="API Key"
              onClick={() => {setactivePopupApi(true)}}
            >
              <FaKey/>
            </Button>
          )}
          {isCustomerAdmin && isFrontPage && (
            <div
              className="globalmessage-button"
            >
              <Button
                variant="primary"
                title="Edit System Prompts"
                onClick={() => {
                  setShowPrompt(true)
                }}
              >
                Chatbot System Prompts
              </Button>
            </div>
          )}
        </div>

        <div className="rightmenu-button">
          <Button
            variant="primary"
            onClick={() => {
              window.open("https://arnvind.com/plannertech-admin-help/?pass=SGVqc2FDZWxpbmU=")
            }}
            title="Help"
          >
            ?
          </Button>
          <Button
            variant="primary"
            onClick={() => dispatch(auth.logout()).then(() => navigate("/login"))}
            title={"Logout"}
          >
            <FiLogOut />
          </Button>
        </div>
      </div>

      {showToken && (
        <Popup
          nonScrollable
          className="tokenPopupContainer"
          onCancel={() => {
            dispatch(token.hideToken()) // Delete the token from redux
            setShowToken(false)
            setactivePopupApi(false)
          }}
        >
          <div>
            <h4>Permanent API token</h4>
            <p>
              This is a permanent API token, which allows you to access your team via our API. Copy and store it in a
              save place.
            </p>
            <Container>
              <Title>API token</Title>
              <Title>
                {tokenString}{" "}
                <span title="copy to clipboard" className="teamconfig-copy-clipboard">
                  <FiCopy onClick={handleCopy} />
                </span>
              </Title>
            </Container>
          </div>
          <Button
            className="close-popup-button"
            variant="primary"
            onClick={() => {
              dispatch(token.hideToken()) // Delete the token from redux
              setShowToken(false)
            }}
          >
            Ok
          </Button>
        </Popup>
      )}
    </>
  )
}
