import React from "react"
import { useDispatch, useSelector } from "react-redux"
import { Route, Routes, useLocation } from "react-router-dom"

import Location from "./Location"
import Team from "./Team"
import RepositoryScreen from "../RepositoryScreen"
import { ScheduleButtonsAdmin } from "components"

import "./index.scss"

import { seatInfo } from "state_management"

// Returns false if not customer admin
function getSeats(customers, companyId) {
  if (Object.keys(customers).length === 0) {
    return false
  }
  return [customers[companyId].seatsUsed, customers[companyId].seatsTotal, customers[companyId].seatsPurchased]
}

export default function AdministratorScreen() {
  const dispatch = useDispatch()
  const companyName = useSelector((state) => state.auth.companyName)
  const { hasFetched, seatsUsed, seatsBought, seatsTotal } = useSelector((state) => state.seatInfo)
  const customers = useSelector((state) => state.customerAdministration.customers)

  const companyId = useSelector((state) => state.auth.companyId)

  if (!hasFetched) {
    dispatch(seatInfo.fetch())
  }

  const location = useLocation()
  // Admin frontpage: /locations
  const seats = getSeats(customers, companyId) || [seatsUsed, seatsTotal, seatsBought]

  const isRepositoryView = location.pathname.includes("/repository/")
  return (
    <div className="administrator-container">
      <ScheduleButtonsAdmin />
      {!isRepositoryView && (
        <div className="administrator-seats">
          <p className="seats">Active seats: {typeof seats[0] === "number" ? seats[0] : "..."}</p>
          <p className="seats">Total seats: {typeof seats[1] === "number" ? seats[1] : "..."}</p>
          <p className="seats">Purchased seats: {typeof seats[2] === "number" ? seats[2] : "..."}</p>
        </div>
      )}
      {!isRepositoryView && (
        <>
          <div className="company-headline">
            <h1>{companyName}</h1>
          </div>
        </>
      )}
      <div className="location-team-space">
        <Routes>
          <Route path="/" element={<Location />} />
          <Route path="/:locationId/teams" element={<Team />} />
          <Route path="/repository/:repositoryId" element={<RepositoryScreen />} />
        </Routes>
      </div>
    </div>
  )
}
