import React, { useEffect, useState, useLayoutEffect, useRef } from "react"
import { useSelector, useDispatch } from "react-redux"
import { useLocation } from "react-router-dom"

import { integrationSheets } from "state_management"

import EditSheets from "./EditSheets"
import UploadDropzone from "./UploadDropzone"
import IcalIntegration from "./IcalIntegration"
import UploadButton from "./UploadButton"
import DeleteUnplannedProjects from "./DeleteUnplannedProjects"
import DeleteUnplannedSamples from "./DeleteUnplannedSamples"
import DeleteTestData from "./DeleteTestData"
import ErrorPopup from "./ErrorPopup"
import { Popup, IntegrationResultMessage } from "components"
import { Button } from "react-bootstrap"
import { AiOutlineExclamation } from "react-icons/ai"

import "./index.scss"

export default function IntegrationTab() {
  const [resultMessage, setResultMessage] = useState(null)
  const [filesManual, setFilesManual] = useState([])
  const [filesAutomatic, setFilesAutomatic] = useState([])

  const showProjects = useSelector((state) => state.teamStatus.showProjects)
  const showAnalyses = useSelector((state) => state.teamStatus.showAnalyses)
  const errors = useSelector((state) => state.integrationSheets.errors)

  const [showErrors, setShowErrors] = useState(false)

  const location = useLocation()
  const dispatch = useDispatch()
  const targetRef = useRef()

  useEffect(() => {
    dispatch(integrationSheets.fetchErrors())
  }, [dispatch])

  useLayoutEffect(() => {
    if (location.state?.scrollToAutomatic && targetRef.current) {
      const timer = setTimeout(() => {
        targetRef.current.scrollIntoView({ behavior: "smooth" });
      }, 10); // Delay of 10ms, it does not scroll without it

      return () => clearTimeout(timer);
    }
  }, [location, targetRef]);


  const handleShowErrors = () => {
    dispatch(integrationSheets.fetchErrors())
    setShowErrors(true)
  }

  return (
    <div className="integration-tab">
      {errors && <ErrorPopup showErrors={showErrors} errors={errors} setShowErrors={setShowErrors} />}
      {resultMessage && (
        <Popup
          onCancel={() => setResultMessage(null)}
          title={resultMessage.aborted ? "Upload aborted!" : "Upload complete!"}
        >
          <IntegrationResultMessage resultMessage={resultMessage} setResultMessage={setResultMessage} />
        </Popup>
      )}

      <p>iCalendar / Outlook Integration</p>
      <IcalIntegration />

      {showProjects && (
        <>
          <hr />
          <p>Delete all Unplanned Projects</p>
          <DeleteUnplannedProjects />
        </>
      )}

      {showAnalyses && (
        <>
          <hr />
          <p>Delete all Unplanned Samples</p>
          <DeleteUnplannedSamples />
        </>
      )}

      {process.env.REACT_APP_ENV !== "production" && (
        <>
          <hr />
          <p>Delete all {showProjects ? "Project" : showAnalyses ? "Analysis" : ""} Test Data</p>
          <DeleteTestData />
        </>
      )}
      {(showProjects || showAnalyses) && (
        <>
          <hr />
          <p><b>Manual Sheet Configuration</b></p>
          <EditSheets />

          <p>File Upload</p>
          <UploadDropzone files={filesManual} onSubmit={setFilesManual} />
          <UploadButton route="/upload/files" files={filesManual} setFiles={setFilesManual} setResultMessage={setResultMessage} />
        </>
      )}
      <>
        <hr style={{ height: 3, opacity: 1, marginTop: '8rem', marginBottom: '8rem'}} />
        <div className="headerContainer">
          <p><b>Automatic Sheet Configuration</b></p>
          <Button onClick={handleShowErrors}>
            Show last error log
            {errors && errors.length !== 0 && <AiOutlineExclamation size={24} style={{ color: 'red', position: 'absolute', right: '-0.3em', bottom: '0.4em'}} title="The sample has highest priority" /> }
          </Button>
        </div>
        <div className="automatic-sheet-container" ref={targetRef}>
          <EditSheets isAutomatic />
        </div>
        <p>File Upload</p>
        <UploadDropzone files={filesAutomatic} onSubmit={setFilesAutomatic} />
        <div className="upload-container">
          <UploadButton
            route="/upload/raw-data"
            files={filesAutomatic}
            setFiles={setFilesAutomatic}
            setResultMessage={setResultMessage}
          />
        </div>
      </>
    </div>
  )
}
