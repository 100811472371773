import { request } from "utils/request"
import { asyncAction, removeFromMap } from "./common"
import { loading, analyses, message } from "./"

const initialState = {}

export function reducer(state = initialState, action) {
  switch (action.type) {
    case "samples/fetchForPlanned/BEGIN":
    case "samples/fetchForUnplanned/BEGIN":
      return { ...state, [action.args[0]]: { isLoading: true } }
    case "samples/fetchForPlanned":
    case "samples/fetchForUnplanned":
      return { ...state, [action.res.analysisId]: { isLoading: false, items: action.res.items } }
    case "iap/runFillInSamples": // We need to refresh all the samples on analyses, when running fill-in-samples IAP
    case "samples/changePriority": // Reset sample state when changing priority to prevent outdated sample-info
    case "samples/clear":
    case "auth/logout":
    case 'samples/flag-sample':
    case 'samples/unflag-sample':
      return initialState
    case "samples/delete-unlocked":
    case "samples/fail-samples":
    case "samples/unplan-samples":
    case "samples/assign-samples":
      return state
    case "analysisTasks/move": // When moving a task, the sample status may have changed
      return removeFromMap(state, { id: action.res.analysisId })
    default:
      return state
  }
}

// Fetch only for one analysis at a time
export const fetchForPlanned = asyncAction("samples/fetchForPlanned", (dispatch, analysisId) => {
  return request("GET", `/analysis/${analysisId}/samples`).then((res) => ({ analysisId, items: res.items }))
})

export const fetchForUnplanned = asyncAction("samples/fetchForUnplanned", (dispatch, analysisId) => {
  return request("GET", `/analysis/unplanned/${analysisId}/samples`).then((res) => ({ analysisId, items: res.items }))
})

export const removeAllUnplanned = asyncAction("samples/delete-unlocked", (dispatch) => {
  return request("DELETE", `/samples/delete-unlocked`).then((res) => {
    // fetching the updated data which will now be empty unless it's planned
    dispatch(loading.fetchAll())
    return res
  })
})

export const failSamples = asyncAction("samples/fail-samples", (dispatch, analysisId, sampleIds, isSampleView=false, sampleName="", productName="") => {
  return request("DELETE", `/samples/fail-samples`, { patch: { ids: sampleIds } }).then((res) => {
    dispatch(analyses.fetchUnplanned())
    dispatch(analyses.fetchPlanned())     // Need to update duration of each task when editing in analysistaskconfig
    dispatch(fetchForPlanned(analysisId)) // needs analysisId
    return res
  })
})

// The fourth parameter 'samples' is only used in plannableSamples.js
export const unplanSamples = asyncAction("samples/unplan-samples", (dispatch, analysisId, sampleIds, samples) => {
  return request("PATCH", `/samples/unplan-samples`, { patch: { ids: sampleIds } }).then((res) => {
    dispatch(analyses.fetchUnplanned())
    dispatch(analyses.fetchPlanned())
    dispatch(fetchForPlanned(analysisId)) // needs analysisId
    return res
  })
})

export const assignSamples = asyncAction("samples/assign-samples", (dispatch, analysisId, sampleIds) => {
  return request("PATCH", `/samples/assign-samples/${analysisId}`, { patch: { ids: sampleIds } }).then((res) => {
    dispatch(analyses.fetchUnplanned())
    dispatch(fetchForPlanned(analysisId)) // needs analysisId
    if (res && res.message) {
      dispatch(message.warning(res.message))
    }
    return res
  })
})

export function clear() {
  return { type: "samples/clear" }
}

export const runSampleAllocation = asyncAction("teamStatus/runSampleAllocation", async (dispatch) => {
  return await request("POST", "/samples/run-sample-allocation").then(async () => {
    await dispatch(analyses.fetchUnplanned())
  })
})

export const changePriority = asyncAction("samples/changePriority", (dispatch, newPriority, newSampleName) => {
  const sortingParameter = window.store.getState().teamStatus.sortingParameter
  return request("PATCH", `/samples/change-priority`, { patch: { priority: newPriority, sampleName: newSampleName } }).then((res) => {
    res.sortingParameter = sortingParameter
    return res
  })
})

export const flagSample = asyncAction("samples/flag-sample", (dispatch, analysisId, sampleName) => {
  return request("PATCH", `/sample/${analysisId}`, { patch: { isFlagged: true } }).then(() => {
    dispatch({
      type: "samples/flag-sample",
      payload: { sampleName, analysisId },
    });
  });
});


export const unflagSample = asyncAction("samples/unflag-sample", (dispatch, analysisId, sampleName) => {
  return request("PATCH", `/sample/${analysisId}`, { patch: { isFlagged: false } }).then(() => {
    dispatch({
      type: "samples/unflag-sample",
      payload: { sampleName, analysisId },
    });
  });
});

