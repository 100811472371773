import React, { useState } from "react"
import { useSelector, useDispatch } from "react-redux"
import Button from "react-bootstrap/Button"
import InputGroup from "react-bootstrap/InputGroup"
import Form from 'react-bootstrap/Form'

import "./index.scss"

import { ConfigContainer, Loading } from "components"
import { Title, Container, DurationInput, Text, DayPicker, Select } from "components/Form"
import { whiteTasks, message, constraintChecks } from "state_management"
import { RecurrenceConfig } from "components/Config"
import { DateTime, Duration, IANAZone } from "luxon"

// Both used when creating white tasks/green tasks and when editing them.
export default function WhiteTaskConfig({ task, onClose, isAssetView, type, bookedAndFreeAssets }) {
  function recurrenceCheckDay(flow,date) {

    let occurences = 0
    date.c.minute = 0
    date.c.hour = 0
    if(flow === "FROM") {
      endDate.c.hour = 0
      endDate.c.minute = 0
      occurences = Math.floor(endDate.diff(date, 'days').values.days)
    }
    if(flow === "TO") {
      startDate.c.hour = 0
      startDate.c.minute = 0
      setEndDate(startDate)
      occurences = Math.floor(date.diff(startDate, 'days').values.days)
    }
    setRecurrenceSettings({
      recurrence: 'DAILY',
      amount: occurences < 1 ? 0 : occurences,
      interval: 1,
    })
  }

  const dispatch = useDispatch()
  const isMember = useSelector((state) => state.auth.isMember)
  const memberId = useSelector((state) => state.auth.memberId)
  const members = useSelector((state) => state.members)
  const assets = useSelector((state) => state.asset.assets)
  const memberTimezone = useSelector((state) => state.auth.memberTimezone)
  const [isLoading, setIsLoading] = useState(false)

  const [startDate, setStartDate] = useState(DateTime.fromISO(task.start).setZone(memberTimezone))
  const [oldStartTime, setStartTime] = useState(
    Duration.fromObject({ hours: DateTime.fromISO(task.start).setZone(memberTimezone).hour, minutes: DateTime.fromISO(task.start).setZone(memberTimezone).minute })
  )
  const oldStart = DateTime.fromISO(task.start).setZone(memberTimezone)

  const [endDate, setEndDate] = useState(DateTime.fromISO(task.start).setZone(memberTimezone).plus({ seconds: task.duration }))
  const [oldEndTime, setEndTime] = useState(
    Duration.fromObject({
      hours: DateTime.fromISO(task.start).setZone(memberTimezone).plus({ seconds: task.duration }).hour,
      minutes: DateTime.fromISO(task.start).setZone(memberTimezone).plus({ seconds: task.duration }).minute,
    })
  )
  const [name, setName] = useState(task.name || "")
  const [description, setDescription] = useState(task.description || "")

  const [memberIds, setMemberIds] = useState(task.memberIds || [])
  const [assetIds, setAssetids] = useState(task.assetIds || [])
  const [showRecurrence, setShowRecurrence] = useState(false)
  const [recurrenceSettings, setRecurrenceSettings] = useState({
    recurrence: task.recurrenceFrequency ? task.recurrenceFrequency : "DAILY",
    amount: task.recurrenceAmountOfReoccurrences ? task.recurrenceAmountOfReoccurrences : 0,
    interval: task.recurrenceInterval ? task.recurrenceInterval : 0,
  })

  const [isOperational, setIsOperational] = useState(task.isOperational)
  const [iapIgnore, setIapIgnore] = useState(task.iapIgnore)

  const assignedMembers = Object.values(members).filter(member => member.id === memberIds[0])[0]
  const timeZoneOffset = memberIds.length === 1 ? startDate.setZone(memberTimezone).offset - IANAZone.create(assignedMembers.timezone).offset(startDate) : 0

  // Compare task time with collaborator work hours. If they match, then the 'checkDay' checkbox is activated.
  // This is a fix which omits the use of redux.
  const workHour = memberIds.length === 1 ? assignedMembers.workHours : { min: 0, max: 3600*24-60 }
  // 'oldStartTime && oldEndTime' is to make sure that the field is filled out before assigning 'taskCompare' and 'workHourCompare'
  const taskCompare = oldStartTime && oldEndTime ? {start: oldStartTime.as('seconds'), end: oldEndTime.as('seconds') } : {}
  const workHourCompare = oldStartTime && oldEndTime ? {start: workHour.min + 60 * timeZoneOffset, end: workHour.max + 60 * timeZoneOffset } : {}

  const [checkDay, setCheckDay] = useState(
    memberIds.length === 1 && taskCompare.start === workHourCompare.start && taskCompare.end === workHourCompare.end && !isAssetView ? true : false
  )
  const startTime = checkDay && !task.greenTask && !isAssetView ? Duration.fromObject({ seconds: workHour.min + 60 * timeZoneOffset }) : oldStartTime
  const endTime = checkDay && !task.greenTask && !isAssetView ? Duration.fromObject({ seconds: workHour.max + 60 * timeZoneOffset }): oldEndTime


  // expands the popup, because of space issues
  const [dayPickerOneShows, setDayPickerOneShows] = useState(false)
  const [dayPickerTwoShows, setDayPickerTwoShows] = useState(false)

  // If it is the first in a recurrence other possibilities are possible
  const disabled = task.fromOutlook
  const otherMembers = isMember && !task.memberIds.includes(memberId)

  // Make description text-area more dynamic by requiring the same amount of row as the 'description' string
  const descriptionTextRows = description.slice().split(/\r\n|\r|\n/).length || 1

  let sortedCollection = [];
  if (bookedAndFreeAssets?.freeAssets && bookedAndFreeAssets?.bookedAssets) {
    // Combine freeAssets and bookedAssets first, and mark them appropriately
    const combinedAssets = [
      ...bookedAndFreeAssets.freeAssets.map(assetId => ({
        ...assets[assetId],
        status: 'Free',
      })),
      ...bookedAndFreeAssets.bookedAssets.map(assetId => ({
        ...assets[assetId],
        status: 'Booked',
      }))
    ];

    // Sort the combined assets alphabetically by their name
    const sortedAssets = combinedAssets
    .filter(asset => asset.name)
    .sort((a, b) => a.name.localeCompare(b.name))
      .map(asset => ({
        ...asset,
        name: `${asset.name} (${asset.status})` // Append status (Free/Booked) to the name
      }));

    // Assign the sorted assets to sortedCollection
    sortedCollection.push(...sortedAssets);
  }

  const membersOnPlan = Object.values(members)
    .filter((member) => !member.archived)
    .sort((a, b) => {
      if (a.initials.toUpperCase() < b.initials.toUpperCase()) return -1
      if (a.initials.toUpperCase() > b.initials.toUpperCase()) return 1
      return 0
    })

    const handleSetMemberIds = (selected) => {
      if(selected.find(value => value === "ALL") ) {
        setMemberIds(membersOnPlan.map(member => member.id))
      } else {
        setMemberIds(selected)
      }
  }

  return (
    <>
      {isLoading && <Loading />}
      {!isLoading && (
        <ConfigContainer
          title={
            task.fromOutlook
              ? "Imported task"
              : task.greenTask
                ? "OFFSITE Calender"
                : task.id
                  ? "Edit task"
                  : "Add a new task"
          }
          closeInsteadOfCancel={task.fromOutlook && task.iapIgnore === iapIgnore}
          disabled={disabled}
          cancelHandler={onClose}
          canCancelWhenDisabled
          canSaveWhenDisabled={task.fromOutlook && task.iapIgnore !== iapIgnore}
          canDeleteWhenDisabled={!otherMembers && !task.fromOutlook}
          submitHandler={() => {
            setIsLoading(true)
            if(checkDay && memberIds.length !== 1 && !isAssetView) {
              dispatch(message.warning("Only one collaborator may be selected when checking the entire workday"))
            } else {
              const start = startDate.set({ hour: 0, minute: 0, second: 0 }).plus(startTime)
              const end = endDate.set({ hour: 0, minute: 0, second: 0 }).plus(endTime)
              const res = {
                name,
                start: start.setZone(memberTimezone).toISO(),
                duration: end.diff(start).as("seconds"),
                description,
                memberIds,
                assetIds,
                greenTask: task.greenTask,
                isOperational: isOperational,
                iapIgnore: iapIgnore,
              }

              const recurrenceRes = {
                ...res,
                amountOfReoccurrences: recurrenceSettings.amount,
                frequency: recurrenceSettings.recurrence,
                interval: recurrenceSettings.interval,
                oldStart: oldStart.setZone(memberTimezone).toISO()
              }

              if (task.id) {
                if (task.recurrenceId) {
                  recurrenceRes.whiteTaskId = task.recurrenceId
                  dispatch(whiteTasks.editRecurrence(task.recurrenceId, recurrenceRes, task.greenTask)).then(() => {
                    onClose()
                  })
                    .finally(() => {
                      setIsLoading(false)
                    })
                } else if (recurrenceSettings.amount) {
                  recurrenceRes.whiteTaskId = task.id
                  dispatch(whiteTasks.createRecurrence(recurrenceRes, task.greenTask)).then(() => {
                    onClose()
                  })
                    .finally(() => {
                      setIsLoading(false)
                    })
                } else {
                  dispatch(whiteTasks.edit(task.id, res, task.greenTask)).then(() => {
                    dispatch(constraintChecks.allConstraintChecks)
                    onClose()
                  })
                    .finally(() => {
                      setIsLoading(false)
                    })
                }
              } else {
                if (recurrenceSettings.amount) {
                  recurrenceRes.whiteTaskId = null
                  dispatch(whiteTasks.createRecurrence(recurrenceRes, task.greenTask)).then(() => {
                    onClose()
                  })
                    .finally(() => {
                      setIsLoading(false)
                    })
                } else {
                  dispatch(whiteTasks.create(res, task.greenTask)).then(() => {
                    dispatch(constraintChecks.allConstraintChecks)
                    onClose()
                  })
                }
              }
            }
          }}
          deleteHandler={
            task.recurrenceId
              ? () =>
                dispatch(
                  whiteTasks.removeRecurrence(task.recurrenceId, { dateOfDeleted: task.start }, task.greenTask)
                ).then(() => {
                  onClose()
                  dispatch(whiteTasks.fetch())
                })
              : task.id
                ? () => dispatch(whiteTasks.remove(task.id, task.greenTask)).then(() => {
                  dispatch(constraintChecks.allConstraintChecks)
                  onClose()
                })
                : undefined
          }
        >
          {!task.greenTask && (
            <Container>
              <Title>Task Name</Title>
              <Text disabled={disabled} required value={name} onChange={setName} />
            </Container>
          )}
          {!task.greenTask && (
            <>
              <div className="greyTaskTimeContainer">
                <div className="greyTaskTime">
                  <Container>
                    <Title>From date</Title>
                    <DayPicker
                      required
                      disabled={disabled}
                      value={startDate}
                      onChange={(event) => {
                        if (!event) {
                          setStartDate(undefined)
                          return
                        }
                        if (event.invalid) {
                          return
                        }
                        setStartDate(event)
                        if(checkDay) {
                          recurrenceCheckDay("FROM",event)
                        }
                      }}
                    />
                    <Title>From time (HH:MM)</Title>
                    <DurationInput disabled={checkDay || disabled} value={startTime} onChange={setStartTime} required isTime />
                  </Container>
                  <Container>
                    <Title>To date</Title>
                    <DayPicker
                      required
                      disabled={disabled}
                      value={endDate}
                      onChange={(event) => {
                        if (!event) {
                          setEndDate(undefined)
                          return
                        }
                        if (event.invalid) {
                          return
                        }
                        setEndDate(event)
                        if(checkDay) {
                          recurrenceCheckDay("TO",event)
                        }
                      }}
                    />
                    <Title>To time (HH:MM)</Title>
                    <DurationInput disabled={checkDay || disabled} value={endTime} onChange={setEndTime} required isTime />
                  </Container>
                </div>
              </div>
              <div className="mb-3 greytask-checkbox">
                <Form.Check
                  defaultChecked={checkDay}
                  type={"checkbox"}
                  id={`greytask-workday`}
                  label={type === "team-plan" ? `Select entire workday` : `Select entire day`}
                  disabled={disabled}
                  onChange={() => {
                    if(memberIds.length !== 1 && !checkDay && !isAssetView) {
                      dispatch(message.warning("Only one collaborator may be selected when checking the entire workday"))
                    }
                    if (workHour.max < workHour.min && !checkDay && startDate.year === endDate.year && startDate.month === endDate.month && startDate.day === endDate.day){
                      setEndDate(endDate.plus({ days: 1 }))
                    }
                    setCheckDay(!checkDay)
                    if (isAssetView) {
                      setStartTime(Duration.fromObject({ hours: 0, minutes: 0 }))
                      setEndTime(Duration.fromObject({ hours: 23, minutes: 59 }))
                    }
                  }}
                />
                <Form.Check
                  defaultChecked={isOperational}
                  type={"checkbox"}
                  id={`greytask-operation`}
                  label={`Operational task`}
                  disabled={disabled}
                  onChange={(e) => setIsOperational(e.target.checked)}
                />
                <Form.Check
                  defaultChecked={iapIgnore}
                  type={"checkbox"}
                  id={`iap-ignore-greytask`}
                  label={`Ignored by IAP`}
                  onChange={(e) => setIapIgnore(e.target.checked)}
                />
              </div>
            </>
          )}
          {task.greenTask && (
            <>
              <Container>
                <Title>From date</Title>
                <DayPicker
                  required
                  disabled={disabled}
                  value={startDate}
                  onChange={(event) => {
                    if (!event) {
                      setStartDate(undefined)
                      return
                    }
                    if (event.invalid) {
                      return
                    }
                    setStartDate(event)
                  }}
                  onDaypickerClick={setDayPickerOneShows}
                />
                <Title>From time (HH)</Title>
                <DurationInput disabled={disabled} value={startTime} onChange={setStartTime} required onlyHours isTime />
              </Container>
              <InputGroup
                className={dayPickerOneShows || dayPickerTwoShows ? "big-white-task-config" : "small-white-task-config"}
              >
                <Title>To date</Title>
                <DayPicker
                  required
                  disabled={disabled}
                  value={endDate}
                  onChange={(event) => {
                    if (!event) {
                      setEndDate(undefined)
                      return
                    }
                    if (event.invalid) {
                      return
                    }
                    setEndDate(event)
                  }}
                  onDaypickerClick={setDayPickerTwoShows}
                />
                <Title>To time (HH)</Title>
                <DurationInput disabled={disabled} value={endTime} onChange={setEndTime} required onlyHours isTime />
              </InputGroup>
            </>
          )}
          {!task.greenTask && (
            <>
              <Container>
                <Title>Description</Title>
                <Text large disabled={disabled} value={description} onChange={setDescription} rows={descriptionTextRows}/>
              </Container>
              <Container>
                <Title>Collaborators</Title>
                <Select
                  name="Collaborators"
                  required={!isAssetView} // Collaborators are not required when the user is in Asset View
                  disabled={disabled}
                  attr="initials"
                  collection={membersOnPlan}
                  value={memberIds}
                  onChange={handleSetMemberIds}
                  allowSelectAll={true}
                  menuPosition="fixed"
                />
              </Container>
            </>
          )}{" "}
          {}
          {!task.fromOutlook && !task.greenTask && (
            <Container>
              <Title>Used Assets</Title>
              <Select
                name="Used Assets"
                required={isAssetView}  // Collaborators are required when in Asset View
                disabled={disabled}
                collection={
                  bookedAndFreeAssets?.freeAssets?.length === 0 && bookedAndFreeAssets?.bookedAssets?.length === 0 ?
                    Object.values(assets)
                    :
                    Object.values(sortedCollection)}
                value={assetIds}
                onChange={setAssetids}
                menuPlacement="bottom"
                menuPosition="fixed"
              />
            </Container>
          )}
          {!task.fromOutlook && (
            <div className="WhiteTaskConfig-Reccurence">
              <Button
                variant="link"
                size="lg"
                onClick={() => {
                  setShowRecurrence(true)
                }}
              >
                Recurrence
              </Button>
            </div>
          )}
          {showRecurrence && (
            <RecurrenceConfig
              recurrenceSettings={recurrenceSettings}
              onClose={() => {
                setShowRecurrence(false)
              }}
              onSubmit={setRecurrenceSettings}
              task={task}
            ></RecurrenceConfig>
          )}
        </ConfigContainer>
      )}
    </>
  )
}
