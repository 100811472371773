import React, { useState, useMemo } from "react"
import { useSelector, useDispatch } from "react-redux"
import { ToggleButtonGroup, ToggleButton } from "react-bootstrap"

import "./index.scss"

import ScheduleElementHour from "./ScheduleElementHour"
import ScheduleAction from "./ScheduleAction"
import ScheduleWhiteTask from "./ScheduleWhiteTask"
import ScheduleAnalysisTask from "./ScheduleAnalysisTask"
import { Popup } from "components"

import { ActionConfig, WhiteTaskConfig, AnalysisTaskConfig } from "components/Config"
import { SampleTableForPlannedAnalysis } from "components/SampleTable"

import { analysisTasks, analyses, projectActions, projects, whiteTasks } from "state_management"

import { buildHours, getWidth } from "utils/scripts/schedule"
import { DateTime } from "luxon"
import isOnMobile from "utils/scripts/isOnMobile"
import useMemoizeArray from "utils/scripts/useMemoizeArray"

// The schedule items / hours on a single member, and a single date.
export default function ScheduleElement({
  date,
  memberId,
  minMemberHeight,
  maxMemberHeight,
  extended,
  view,
  isMobile,
  isAltPressed,
}) {
  const dispatch = useDispatch()
  const allWhiteTasks = useSelector((state) => state.whiteTasks)
  const allAnalysisTasks = useSelector((state) => state.analysisTasks)
  const allProjectActions = useSelector((state) => state.projectActions.plannedProjectActions)
  const allProjects = useSelector((state) => state.projects)
  const memberTimezone = useSelector((state) => state.auth.memberTimezone)
  const isCompactView = useSelector((state) => state.visual.teamPlan.showCompact)
  const scheduleMondayDate = useSelector((state) => state.visual.scheduleMondayDate)
  const scheduleWithWeek = useSelector((state) => state.visual.scheduleWithWeek)

  const [activeActionId, setActiveActionId] = useState(null)
  const activeAction = useSelector(
    (state) => (activeActionId && state.projectActions.plannedProjectActions[activeActionId]) || null
  )
  const [activeWhiteTaskId, setActiveWhiteTaskId] = useState(null)
  const activeWhiteTask = useSelector((state) => (activeWhiteTaskId && state.whiteTasks[activeWhiteTaskId]) || null)
  const [activeAnalysisTaskId, setActiveAnalysisTaskId] = useState(null)
  const activeAnalysisTask = useSelector(
    (state) => (activeAnalysisTaskId && state.analysisTasks[activeAnalysisTaskId]) || null
  )
  const [activeSamplePopupAnalysisId, setActiveSamplePopupAnalysisId] = useState(null)
  const [bookedAndFreeAssets, setBookedAndFreeAssets] = useState({bookedAssets: [], freeAssets: []})

  const isInPlanningMode = useSelector((state) => state.teamStatus.isInPlanningMode)
  const isCurrentPlanner = useSelector((state) => state.teamStatus.isCurrentPlanner)
  const includeToday = useSelector((state) => state.teamStatus.includeToday)

  const otherPlannerInitials = useSelector((state) => {
    const currentPlannerId = state.teamStatus.currentPlannerId
    if (currentPlannerId === "self") {
      return null
    } else if (currentPlannerId) {
      return state.members[currentPlannerId].initials
    } else {
      return null
    }
  })
  const isDayView = view === "day-view"

  const scheduleMondayDateTime = DateTime.fromISO(scheduleMondayDate);

  const startOfWeek = scheduleMondayDateTime.startOf('week');
  const endOfWeek = scheduleWithWeek ? startOfWeek.endOf('week').plus({ weeks: 1 }) : startOfWeek.endOf('week');

  const startOfWeekISO = startOfWeek.toISO();
  const endOfWeekISO = endOfWeek.toISO();



  const tasksInCurrentWeek = useMemo(() => {
    const isInCurrentWeek = ({ start, end }) => {
      return start <= endOfWeekISO && end >= startOfWeekISO;
    };
    const filterTasks = (tasks) => Object.values(tasks).filter(isInCurrentWeek);
    return {
      whiteTasks: filterTasks(allWhiteTasks),
      analysisTasks: filterTasks(allAnalysisTasks),
      projectActions: filterTasks(allProjectActions),
    };
  }, [allWhiteTasks, allAnalysisTasks, allProjectActions, endOfWeekISO, startOfWeekISO]);

// Use tasksInCurrentWeek.whiteTasks, etc., where appropriate

  // This is memoized, so `getWidth` is run as little as possible, even if the
  // tasks / actions change.
  const itemsOnThisDate = useMemoizeArray(
    useMemo(() => {
      const startOfDate = DateTime.fromISO(date).setZone(memberTimezone).startOf("day")
      const endOfDate = startOfDate.endOf("day")
      return [...tasksInCurrentWeek.whiteTasks, ...tasksInCurrentWeek.analysisTasks, ...tasksInCurrentWeek.projectActions]
        .filter(({ memberIds }) => memberIds && memberIds.indexOf(memberId) !== -1)
        .filter(({ start }) => DateTime.fromISO(start).setZone(memberTimezone) <= endOfDate)
        .filter(({ end }) => DateTime.fromISO(end).setZone(memberTimezone) > startOfDate)
        .filter(({ greenTask }) => !greenTask)
        .sort((a, b) => (a.id < b.id ? -1 : a.id > b.id ? 1 : 0)) // Consistent order, see useMemoizeArray
    }, [tasksInCurrentWeek, date, memberId, memberTimezone])
  )
  // This is used to distinguish green from white tasks
  const greenTasksMap = useMemo(() => {
    const rtn = {}
    const startOfDate = DateTime.fromISO(date).setZone(memberTimezone).startOf("day")
    const endOfDate = startOfDate.endOf("day")
    tasksInCurrentWeek.whiteTasks
      .filter(({ memberIds }) => memberIds && memberIds.indexOf(memberId) !== -1)
      .filter(({ start }) => DateTime.fromISO(start).setZone(memberTimezone) <= endOfDate)
      .filter(({ end }) => DateTime.fromISO(end).setZone(memberTimezone) > startOfDate)
      .filter(({ greenTask, __typename }) => __typename === "WhiteTask" && greenTask)
      .forEach((task) => {
        const start = DateTime.fromISO(task.start).setZone(memberTimezone)
        const end = DateTime.fromISO(task.end).setZone(memberTimezone)
        const endIsOtherDateThanStart = start.startOf("day") < end.startOf("day")
        if (endIsOtherDateThanStart && end.day !== DateTime.fromISO(date).setZone(memberTimezone).day) {
          for (let i = start.hour; i < 24; i += extended ? 0.25 : 1) {
            rtn[i] = task
          }
        } else if (endIsOtherDateThanStart && end.day === DateTime.fromISO(date).setZone(memberTimezone).day) {
          for (let i = 0; i < end.hour; i += extended ? 0.25 : 1) {
            rtn[i] = task
          }
        } else {
          for (let i = start.hour; i < end.hour; i += extended ? 0.25 : 1) {
            rtn[i] = task
          }
        }
      })
    return rtn
  }, [tasksInCurrentWeek.whiteTasks, extended, date, memberId, memberTimezone])

  const taskDescriptions = useMemo(() => {
    const startOfDate = DateTime.fromISO(date).setZone(memberTimezone).startOf("day")
    const endOfDate = startOfDate.endOf("day")
    const itemWithShouldShowState = itemsOnThisDate.map((item) => {
      const start = DateTime.fromISO(item.start).setZone(memberTimezone)
      const end = DateTime.fromISO(item.end).setZone(memberTimezone)
      if (start >= startOfDate && end <= endOfDate) {
        return { ...item, shouldShowState: 1 }
      } else if (start >= startOfDate) {
        return { ...item, end: endOfDate.setZone(memberTimezone).toISO(), shouldShowState: 2 }
      } else if (end <= endOfDate) {
        return { ...item, start: startOfDate.setZone(memberTimezone).toISO(), shouldShowState: 3 }
      } else {
        return { ...item, start: startOfDate.setZone(memberTimezone).toISO(), end: endOfDate.toISO(), shouldShowState: 4 }
      }
    })

    return getWidth(startOfDate, itemWithShouldShowState, 24 * 60)
  }, [itemsOnThisDate, date, memberTimezone])

  const renderedTasks = useMemo(() => {
    const minHeight = extended ? 1.25 : Math.min(5, (0.5 / (maxMemberHeight - minMemberHeight)) * 100)
    let daySize = maxMemberHeight - minMemberHeight
    if (extended) {
      daySize = daySize * 4
    }
    return taskDescriptions.map(({ width, left, obj }) => {
      const absoluteHeight = Math.floor(DateTime.fromISO(obj.end).setZone(memberTimezone).diff(DateTime.fromISO(obj.start).setZone(memberTimezone)).as("minutes")) / 60
      const h = (absoluteHeight / (maxMemberHeight - minMemberHeight)) * 100
      const absoluteDist =
        Math.floor(DateTime.fromISO(obj.start).setZone(memberTimezone).hour) +
        Math.ceil(DateTime.fromISO(obj.start).setZone(memberTimezone).minute) / 60 -
        minMemberHeight
      const top = (absoluteDist / (maxMemberHeight - minMemberHeight)) * 100
      const style = {
        position: "absolute",
        width: width + "%",
        left: left + "%",
        height: isMobile ? 28 * absoluteHeight * 4 + "px" : h + "%",
        top: top + "%",
        minHeight: minHeight + "%",
        fontSize: isCompactView && !extended ? "0.5rem" :
          Math.max(
            Math.min(((Math.max(minHeight, h) / 100) * daySize) / 1.1, 1.1),
            isDayView ? (h / 7 > 1.5 ? 1.5 : 0.6) : 0.6
          ) + "rem",
      }

      if (obj.__typename === "ProjectAction") {
        return (
          <ScheduleAction
            key={obj.id + " action"}
            actionId={obj.id}
            shouldShowState={obj.shouldShowState}
            style={style}
            onClick={() => {
              dispatch(projectActions.getBookedFreeAssets(obj.id, allProjectActions[obj.id])).then((res) => {
                setBookedAndFreeAssets(res)
              })
              setActiveActionId(obj.id)
            }}
            showIcons={!isCompactView || extended}
          />
        )
      } else if (obj.__typename === "AnalysisTask") {
        const shouldShowSampleCount = ((!extended && absoluteHeight >= 2.1) || (extended && absoluteHeight >= 0.55)) && !isCompactView
        return (
          <ScheduleAnalysisTask
            key={obj.id + " analysis_task"}
            taskId={obj.id}
            shouldShowState={obj.shouldShowState}
            shouldShowSampleCount={shouldShowSampleCount}
            style={style}
            memberId={memberId}
            onClick={() => {
              dispatch(analysisTasks.getBookedFreeAssets(obj.id, allAnalysisTasks[obj.id])).then((res) => {
                setBookedAndFreeAssets(res)
              })
              setActiveAnalysisTaskId(obj.id)
              setActiveSamplePopupAnalysisId(allAnalysisTasks[obj.id].analysisId)
            }}
            isAltPressed={isAltPressed}
            showIcons={!isCompactView || extended}
          />
        )
      } else if (!obj.greenTask) {
        return (
          <ScheduleWhiteTask
            key={obj.id + " white_task"}
            taskId={obj.id}
            shouldShowState={obj.shouldShowState}
            style={style}
            onClick={() => {
              if (obj.recurrenceInterval === null) {
                dispatch(whiteTasks.getBookedFreeAssets(obj.id, allWhiteTasks[obj.id])).then((res) => {
                  setBookedAndFreeAssets(res)
                })
              } else {
                setBookedAndFreeAssets({bookedAssets: [], freeAssets: []})
              }
              setActiveWhiteTaskId(obj.id)
            }}
          />
        )
      } else {
        return <></>
      }
    })
  }, [taskDescriptions, extended, minMemberHeight, maxMemberHeight, isDayView, isMobile, memberId,
      memberTimezone, allAnalysisTasks, allProjectActions, allWhiteTasks, dispatch, isAltPressed, isCompactView])

  const renderedHours = useMemo(() => {
    const day = DateTime.fromISO(date).setZone(memberTimezone);
    let hours = buildHours(extended, minMemberHeight, maxMemberHeight);

    if (isCompactView) {
      const compactStepSize = extended ? 1 : 2; // Goes back to normal extended view
      let filteredHours = [];

      for (let i = 0; i < hours.length; i += compactStepSize) {
        filteredHours.push(hours[i]);
      }

      // Ensure the last hour is included, checking for unique last hour
      const lastHour = hours[hours.length - 1];
      if (!filteredHours.includes(lastHour)) {
        filteredHours.push(lastHour);
      }

      hours = filteredHours;
    }

    return hours.map((hour) => (
      <ScheduleElementHour
        memberId={memberId}
        date={day.startOf("day").plus({ hours: hour }).toISO()}
        interval={extended ? 0.25 : 1}
        key={hour}
        task={greenTasksMap[hour]}
        isMobile={isMobile}
      />
    ));
  }, [extended, minMemberHeight, maxMemberHeight, date, memberId, greenTasksMap, isMobile, memberTimezone, isCompactView]);

  return (
    <>
      {activeWhiteTask && (
        <Popup nonScrollable onCancel={() => setActiveWhiteTaskId(null)}>
          <WhiteTaskConfig task={activeWhiteTask} onClose={() => setActiveWhiteTaskId(null)} type="team-plan" bookedAndFreeAssets={bookedAndFreeAssets}/>
        </Popup>
      )}
      {activeAction && (
        <Popup nonScrollable onCancel={() => setActiveActionId(null)}>
          <ActionConfig
            title={<h4>Edit Action</h4>}
            action={activeAction}
            isSingleAction={
              activeAction &&
              allProjects[activeAction.projectId] &&
              allProjects[activeAction.projectId].singleActionProject
            }
            cancelHandler={() => setActiveActionId(null)}
            submitHandler={(res) =>
              dispatch(projectActions.editPlanned(activeActionId, res)).then(() => setActiveActionId(null))
            }
            deleteTitle={"Unplan"}
            deleteHandler={() => {
              setActiveActionId(null)
              dispatch(projectActions.removePlanned(activeActionId))
            }}
            shiftDeleteHandler={() => {
              const projectId = allProjects[allProjectActions[activeActionId].projectId].id
              dispatch(projects.unplan(projectId)).then(() => setActiveActionId(null))
            }}
            projectId={!isOnMobile ? activeAction.projectId : null}
            confirmDeletionText={"Are you sure you want to unplan all future actions in this project?"}
            bookedAndFreeAssets={bookedAndFreeAssets}
          />
        </Popup>
      )}
      {activeAnalysisTask && (
        <Popup nonScrollable onCancel={() => {
          setActiveAnalysisTaskId(null)
          setActiveSamplePopupAnalysisId(null)
          setBookedAndFreeAssets({bookedAssets: [], freeAssets: []})
          // Reset activeSamplePopupAnalysisId, to avoid that it carries over to another analysis
        }}>
          <div className="taskTabs">
            <ToggleButtonGroup
              type="radio"
              name="taskTabs_name"
              defaultValue={"SAMPLE"}
            >
              <ToggleButton
                title="View Analysis Samples"
                variant="primary"
                value="SAMPLE"
                id="analysis-sample-view_id"
                className="task-tab-btn"
                onChange={() => setActiveSamplePopupAnalysisId(activeAnalysisTask.analysisId)}
              >
                View Analysis Samples
              </ToggleButton>
              <ToggleButton
                title="Edit Analysis-task"
                variant="primary"
                value="TASK"
                id="analysis-task-view_id"
                className="task-tab-btn"
                onChange={() => setActiveSamplePopupAnalysisId(null)}
              >
                Edit Analysis-task
              </ToggleButton>
            </ToggleButtonGroup>
          </div>
          <div className="taskAndSampleContainer">
            {!activeSamplePopupAnalysisId && (
              <AnalysisTaskConfig
                task={activeAnalysisTask}
                cancelHandler={() => {
                  setActiveAnalysisTaskId(null)
                  setBookedAndFreeAssets({bookedAssets: [], freeAssets: []})
                }}
                submitHandler={(res) =>
                  dispatch(analysisTasks.edit(activeAnalysisTaskId, res)).then(() => {
                    setActiveAnalysisTaskId(null)
                    setBookedAndFreeAssets({bookedAssets: [], freeAssets: []})
                    if (activeAnalysisTask.analysisId) {
                      dispatch(analyses.setMovable(activeAnalysisTask.analysisId))
                    }
                  })
                }
                deleteHandler={() => {
                  dispatch(analyses.remove(activeAnalysisTask.analysisId)).then(() => {
                    setActiveAnalysisTaskId(null)
                    setBookedAndFreeAssets({bookedAssets: [], freeAssets: []})
                  })
                }}
                bookedAndFreeAssets={bookedAndFreeAssets}
              />
            )}
            {activeSamplePopupAnalysisId && <SampleTableForPlannedAnalysis analysisId={activeSamplePopupAnalysisId} cancelHandler={() => {
              setActiveAnalysisTaskId(null)
              setActiveSamplePopupAnalysisId(null)
              // Reset activeSamplePopupAnalysisId, to avoid that it carries over to another analysis
            }} />}
          </div>
        </Popup>
      )}
      <div className="ScheduleElement-container">
        {renderedHours}
        {renderedTasks}
        {isInPlanningMode &&
          !isCurrentPlanner &&
          ((!includeToday && DateTime.fromISO(date).setZone(memberTimezone) > DateTime.local().setZone(memberTimezone).startOf("day")) ||
            (includeToday && DateTime.fromISO(date).setZone(memberTimezone) >= DateTime.local().setZone(memberTimezone).startOf("day"))) && (
            <div className="ScheduleElement-planning-overlay">
              <p>{otherPlannerInitials} is simulating...</p>
            </div>
          )}
        {isInPlanningMode &&
          isCurrentPlanner &&
          ((!includeToday && DateTime.fromISO(date).setZone(memberTimezone) <= DateTime.local().setZone(memberTimezone).startOf("day")) ||
            (includeToday && DateTime.fromISO(date).setZone(memberTimezone) < DateTime.local().setZone(memberTimezone).startOf("day"))) && (
            <div className="ScheduleElement-planning-overlay">
              <p>You are in simulation mode</p>
            </div>
          )}
      </div>
    </>
  )
}
