import React, { useRef, useEffect, useState } from "react"
import { useDrop } from "react-dnd"
import { useSelector, useDispatch } from "react-redux"

import dragTypes from "utils/scripts/dragTypes"
import { arraysAreEqual } from "utils/scripts/useMemoizeArray"

import { Popup } from "components"
import { WhiteTaskConfig } from "components/Config"
import { DateTime } from "luxon"

import {
  scheduleHover,
  message,
  whiteTasks,
  analysisTasks,
  projectActions,
  analyses,
  multipleDrag,
  iap,
  waiting,
  teamStatus,
} from "state_management"
import { Button } from "react-bootstrap"

// A single hour in the schedule.
// If this is clicked, a config for adding a white task is opened.
export default function ScheduleElementHour({ memberId, date, interval, task, isMobile }) {
  const member = useSelector((state) => state.members[memberId])
  const memberTimezone = useSelector((state) => state.auth.memberTimezone)
  const dispatch = useDispatch()
  const [activeConfig, setActiveConfig] = useState(false)
  const [greenTaskConfig, setGreenTaskConfig] = useState(false)
  const [mobileChoice, setMobileChoice] = useState(false)
  const [bookedAndFreeAssets, setBookedAndFreeAssets] = useState({ bookedAssets: [], freeAssets: [] })
  const IAPconfig = useSelector((state) => state.visual.IAPconfig)
  const IAPconfigRef = useRef(IAPconfig)

  useEffect(() => {
    IAPconfigRef.current = IAPconfig
  }, [IAPconfig])

  const [{ isOver }, drop] = useDrop(
    () => ({
      accept: Object.values(dragTypes),
      collect: (mon) => ({
        isOver: !!mon.isOver(),
      }),
      drop: ({ type, item }) => {
        const start = date


        const recurrenceRes = {
          name: item.name,
          start: date,
          oldStart: date,
          duration: item.duration,
          description: item.description,
          memberIds: item.memberIds,
          assetIds: item.assetIds,
          greenTask: false,
          amountOfReoccurrences: item.recurrenceAmountOfReoccurrences,
          frequency: item.recurrenceFrequency,
          interval: item.recurrenceInterval,
        }

        if (type === dragTypes.MULTIPLETASK) {
          dispatch(multipleDrag.dropAllTasks([memberId], start))
        } else if (type === dragTypes.MULTIPLEACTION) {
          dispatch(multipleDrag.dropAllActions([memberId], start))
        } else if (type === dragTypes.STANDARD_ACTION) {
          dispatch(projectActions.planStandardAction(item.id, { start, memberIds: [member.id] }))
        } else if (type === dragTypes.ACTION) {
          dispatch(projectActions.move(item.id, { start, memberIds: [member.id] }))
        } else if (type === dragTypes.SMART_DRAG) {
          const input = { memberId: member.id }

          if (item.__typename === "AnalysisTask") {
            input.analysisId = item.analysisId
          } else {
            input.unplannedAnalysisId = item.id
          }

          dispatch(
            teamStatus.setIAPconfiguration(IAPconfigRef.current.timePeriod.from, IAPconfigRef.current.timePeriod.to)
          ).then(() => {
            dispatch(waiting.changeIapLoading("Running IAP"))
            dispatch(iap.runFillInSingleAnalysis(input)).finally(() => dispatch(waiting.changeIapLoading("")))
          })
        } else if (type === dragTypes.ANALYSIS_TASK || type === dragTypes.WHITE_TASK) {
          let memberIds = item.memberIds.slice()
          if (!memberIds.includes(member.id)) {
            if (memberIds.length > 1) {
              memberIds.push(member.id)
            } else {
              memberIds = [member.id]
            }
          }
          if (item.__typename === "WhiteTask") {
            if (item["recurrenceId"] && !arraysAreEqual(item.memberIds, memberIds)) {
              dispatch(message.warning("You cannot drag and drop a recurrent grey task"))
            } else if (item["recurrenceId"] && !item.fromOutlook) {
              recurrenceRes.whiteTaskId = item.recurrenceId
              dispatch(whiteTasks.editRecurrence(item.recurrenceId, recurrenceRes, item.greenTask))
            } else if (!item.fromOutlook) {
              dispatch(whiteTasks.move(item.id, { start, memberIds }))
            } else if (item.fromOutlook) {
              dispatch(message.warning("You cannot move imported tasks"))
            }
          } else {
            dispatch(analysisTasks.move(item.id, { start, memberIds }))
          }
        } else {
          dispatch(analyses.moveIntoPlan(item.id, start, member))
        }
      },
    }),
    [dragTypes, date]
  )

  // Cache DateTime computations for date
  const dateInZone = DateTime.fromISO(date, { zone: member.timezone })
  const dateInThisTimezone = DateTime.fromISO(date, { zone: memberTimezone })
  const dateInZoneMillis = dateInZone.toMillis()
  const dateInThisTimezoneMillis = dateInThisTimezone.toMillis()
  const dateInZoneStartOfDayMillis = dateInZone.startOf('day').toMillis()
  const memberWorkHoursMinMillis = member.workHours.min * 1000
  const memberWorkHoursMaxMillis = member.workHours.max * 1000

  const availableOnDate = {
    1: member.workWeek.monday,
    2: member.workWeek.tuesday,
    3: member.workWeek.wednesday,
    4: member.workWeek.thursday,
    5: member.workWeek.friday,
    6: member.workWeek.saturday,
    7: member.workWeek.sunday,
  }

  const currentWeekday = dateInZone.weekday;

  // Inline calculation for previous weekday
  const previousWeekday = currentWeekday === 1 ? 7 : currentWeekday - 1;

  const isPreviousDayWorkday = availableOnDate[previousWeekday];

  const overMidnight = member.workHours.min > member.workHours.max;

  const timeSinceStartOfDayMillis = dateInZoneMillis - dateInZoneStartOfDayMillis;

  if (!overMidnight) {
    // Handle normal shifts
    if (
      !availableOnDate[currentWeekday] ||
      timeSinceStartOfDayMillis < memberWorkHoursMinMillis ||
      timeSinceStartOfDayMillis >= memberWorkHoursMaxMillis
    ) {
      return <div className="ScheduleElement-div invalid-area" />;
    }
  } else {
    // Handle overnight shifts
    if (
      !(
        // Current day part of the overnight shift
        (availableOnDate[currentWeekday] &&
          timeSinceStartOfDayMillis >= memberWorkHoursMinMillis) ||
        // Early morning part coming from the previous day's overnight shift
        (isPreviousDayWorkday &&
          timeSinceStartOfDayMillis < memberWorkHoursMaxMillis)
      )
    ) {
      return <div className="ScheduleElement-div invalid-area" />;
    }
  }

  const dateInZoneFormatted = dateInThisTimezone.toFormat("HH:mm")
  const dateInZonePlusInterval = DateTime.fromMillis(dateInThisTimezoneMillis + interval * 3600000).setZone(memberTimezone)
  const dateInZonePlusIntervalFormatted = dateInZonePlusInterval.toFormat("HH:mm")
  const dateInZoneISO = dateInZone.toISO()

  return (
    <>
      {mobileChoice && (
        <Popup nonScrollable onCancel={() => setMobileChoice(false)}>
          <div className="ScheduleElementHour-mobileChoice">
            <h4>Choose what to add</h4>
            <div className="ScheduleElementHour-mobileChoice-content">
              <Button
                variant="primary"
                onClick={() => {
                  setMobileChoice(false)
                  setActiveConfig(true)
                }}
              >
                Add a GreyTask
              </Button>
              <Button
                variant="primary"
                onClick={() => {
                  setMobileChoice(false)
                  setGreenTaskConfig(true)
                }}
              >
                {task ? "Edit OFFSITE" : "Add OFFSITE"}
              </Button>
            </div>
            <div className="ScheduleElementHour-mobileChoice-cancel">
              <Button variant="secondary" onClick={() => setMobileChoice(false)}>
                Close
              </Button>
            </div>
          </div>
        </Popup>
      )}
      {activeConfig && (
        <Popup nonScrollable onCancel={() => setActiveConfig(false)}>
          <WhiteTaskConfig
            task={{
              start: dateInZoneISO,
              duration: interval * 3600, // Duration in seconds
              memberIds: [memberId],
            }}
            onClose={() => {
              setActiveConfig(false)
            }}
            type="team-plan"
            bookedAndFreeAssets={bookedAndFreeAssets}
          />
        </Popup>
      )}
      {greenTaskConfig && (
        <Popup
          className={isMobile ? "Mobile-Green-task-config-popup" : "Green-task-config-popup"}
          onCancel={() => setGreenTaskConfig(false)}
        >
          <WhiteTaskConfig
            task={
              task || {
                start: dateInZoneISO,
                duration: 3600, // 1 hour in seconds
                memberIds: [memberId],
                greenTask: true,
                name: date + memberId,
              }
            }
            onClose={() => setGreenTaskConfig(false)}
            type="team-plan"
          />
        </Popup>
      )}
      <div
        ref={drop}
        className={"ScheduleElement-div" + (task ? " green-task" : "") + (isOver ? " drag-over" : "")}
        onMouseLeave={() => dispatch(scheduleHover.setMemberHour(null, null))}
        onMouseEnter={() => dispatch(scheduleHover.setMemberHour(dateInZoneFormatted, memberId))}
        onDoubleClick={() => {
          isMobile ? setMobileChoice(true) : setActiveConfig(true)
          dispatch(whiteTasks.getBookedFreeAssets(null, { start: dateInZoneISO })).then((res) => {
            setBookedAndFreeAssets(res)
          })
        }}
        onClick={(e) => {
          if (e.shiftKey) {
            setGreenTaskConfig(true)
            // Green task config contains input elements and user-select: none; can't be used properly
            document.getSelection().removeAllRanges()
          }
        }}
        title={`${dateInZoneFormatted} to ${dateInZonePlusIntervalFormatted}`}
      />
    </>
  )
}



