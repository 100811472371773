import { request } from "utils/request"
import { asyncAction, addToList, removeFromList } from "./common"
const initialState = {
  isLoading: false,
  isFetching: false,
  conversation: [],
  status: "initial",
  availableRepositories: [],
  selectedChatbot: {
    name: "",
    id: ""
  },
  template: "",
  chatbots: [],
  generalPrefix: "",
  lastQuestion: "",
  relevantDocumentation: ""
}

const newChatState = (state, defaultChatbotId) => {
  const defaultChatbot = state.chatbots.find((chatbot) => chatbot.id === defaultChatbotId)
  return {
    ...initialState,
    chatbots: state.chatbots,
    availableRepositories: state.availableRepositories,
    selectedChatbot: defaultChatbot
  }
}

export function reducer(state = initialState, action) {
  switch (action.type) {
    case "librarian/fetchRepositories/BEGIN":
      return { ...state, isLoading: true }
    case "librarian/fetchRepositories":
      return { ...state, isLoading: false, availableRepositories: action.res }
    case "librarian/fetchChatbots/BEGIN":
      return { ...state, isLoading: true }
    case "librarian/fetchChatbots":
      return { ...state, isLoading: false, chatbots: action.res }
    case "librarian/fetchAdminChatbots/BEGIN":
      return { ...state, isLoading: true }
    case "librarian/fetchAdminChatbots":
      return { ...state, isLoading: false, chatbots: action.res }
    case "librarian/getAnswer/BEGIN":
      return { ...state, isFetching: true, status: "begun" }
    case "librarian/getAnswer/ERROR":
      return { ...state, isFetching: false, status: "failed" }
    case "librarian/getAnswer":
      return {
        ...state,
        conversation: [...state.conversation, action.res],
        isFetching: false,
        status: "finished",
      }
    case "librarian/getSpeechToTextQuestion":
      return {
        ...state,
        isFetching: false,
        status: "finished",
      };
    case "librarian/addToConversation":
      return { ...state, conversation: [...state.conversation, action.message] }
    case "librarian/setChatbot":
      return { ...state, selectedChatbot: action.selectedChatbot }
    case "librarian/setTemplate":
      return { ...state, template: action.template }
    case "librarian/refreshChat":
      return newChatState(state, action.defaultChatbot)
    case "librarian/updateHelpRepository/BEGIN":
      return { ...state, isLoading: true }
    case "librarian/updateHelpRepository":
      return { ...state, isLoading: false, ...action.res }
    case "librarian/updateHelpRepository/ERROR":
      return { ...state, isLoading: false, error: action.error }
    case "librarian/createTeamRepository":
      return {
        ...state,
        availableRepositories: addToList(state.availableRepositories, { ...action.res.repository, uniqueForTeam: true }),
      }
    case "librarian/editRepository":
        return {
          ...state,
          availableRepositories: state.availableRepositories.map((item) => (item.id === action.args[0] ? {
            ...item,
            name: action.res.repository.name
          } : item))
        }
    case "repositories/remove":
      return {
        ...state,
        availableRepositories: removeFromList(state.availableRepositories, action.res)
      }
    case "librarian/deleteChatbot":
      return {
        ...state,
        chatbots: removeFromList(state.chatbots, action.res)
      }
    case "librarian/createChatbot":
      return {
        ...state,
        chatbots: addToList(state.chatbots, { ...action.res.chatbot, uniqueForTeam: true })
      }
    case "librarian/createAdminChatbot":
      return {
        ...state,
        chatbots: addToList(state.chatbots, { ...action.res.chatbot, uniqueForTeam: false })
      }
    case "librarian/createGlobalChatbot":
      return {
        ...state,
        chatbots: addToList(state.chatbots, { ...action.res.chatbot, uniqueForTeam: false })
      }
    case "librarian/editChatbot":
      return {
        ...state,
        chatbots: state.chatbots.map((item) => (item.id === action.args[0] ? {
          ...item,
          name: action.res.chatbot.name,
          systemPrompt: action.res.chatbot.systemPrompt,
          repositories: action.res.chatbot.repositories
        } : item))
      }
    case "librarian/editAdminChatbot":
      return {
        ...state,
        chatbots: state.chatbots.map((item) => (item.id === action.args[0] ? {
          ...item,
          name: action.res.chatbot.name,
          systemPrompt: action.res.chatbot.systemPrompt,
          repositories: action.res.chatbot.repositories
        } : item))
      }
    case "librarian/setGeneralPrefix":
      return { ...state, generalPrefix: action.res.prefix }
    case "librarian/setRelevantDocumentation":
      return { ...state, relevantDocumentation: action.res.relevantDocumentation }
    case "librarian/setLastQuestion":
      return { ...state, lastQuestion: action.res.lastQuestion }
    case "librarian/fetchConfig":
      return { ...state,
        generalPrefix: action.res.general_prefix,
        lastQuestion: action.res.last_questions_hint,
        relevantDocumentation: action.res.relevant_documentation_string }
    case "auth/login":
    case "auth/verifyMfaToken":
    case "auth/logout":
      return initialState
    default:
      return state
  }
}

export const fetchRepositories = asyncAction("librarian/fetchRepositories", (dispatch) => {
  return request("GET", `/librarian/repository/user-repositories`).then((res) => {
    return res.items
  })
})

export function addToConversation(message) {
  return (dispatch) => {
    dispatch({ type: "librarian/addToConversation", message })
  }
}

export const getAnswer = asyncAction("librarian/getAnswer", (dispatch, message, repositoryId) => {
  return request("POST", `/librarian/chat/chatbot/${repositoryId}`, { input: { message: message } }).then((res) => {
    return res.answer
  })
})

export const getSpeechToTextQuestion = asyncAction("librarian/getSpeechToTextQuestion", (dispatch, audioFile) => {
  const formData = new FormData();
  formData.append('audioFile.wav', audioFile);
    return request("POST", `/librarian/speech-to-text`, formData, false).then((res) => {
      return { ...res}
    })
});

export const setChatbot = (selectedChatbot => {
  return (dispatch) => {
    dispatch({ type: "librarian/setChatbot", selectedChatbot })
  }
})

export const setTemplate = (template) => {
  return (dispatch) => {
    dispatch({ type: "librarian/setTemplate", template })
  }
}

export function refreshChat() {
  return (dispatch, getState) => {
    const defaultChatbot = getState().auth.defaultChatbot
    dispatch({ type: "librarian/refreshChat", defaultChatbot })
  }
}

export const updateHelpRepository = asyncAction(
  "librarian/updateHelpRepository",
  (dispatch, id) => {
    // Perform the POST request to update the help repository
    return request("POST", `/librarian/extract/pt-help/${id}`)
      .then((res) => {
        // Return the response to be dispatched and handled by the reducer
        return res;
      })
      .catch((error) => {
        console.error('Failed to update help repository:', error);
        // Re-throw the error to allow the asyncAction handler to dispatch an ERROR action
        throw error;
      });
  }
)

export const createTeamRepository = asyncAction(
  "librarian/createTeamRepository",
  (dispatch, input) => {
    return request("POST", `/librarian/repository/create/planner`, { input }).then((res) => res)
  }
)

export const editRepository = asyncAction(
  "librarian/editRepository",
  (dispatch, id, patch) => {
    return request("PATCH", `/librarian/repository/update/planner/${id}`, { patch }).then((res) => res)
  }
)

export const fetchChatbots = asyncAction("librarian/fetchChatbots", (dispatch) => {
  return request("GET", `/librarian/chatbot/user-chatbots`).then((res) => {
    return res.items
  })
})

export const fetchAdminChatbots = asyncAction("librarian/fetchAdminChatbots", (dispatch) => {
  return request("GET", `/librarian/chatbot/admin-chatbots`).then((res) => {
    return res.items
  })
})

export const createChatbot = asyncAction(
  "librarian/createChatbot",
  (dispatch, input) => {
    return request("POST", `/librarian/chatbot/create/planner`, { input }).then((res) => res)
  }
)

export const createAdminChatbot = asyncAction(
  "librarian/createAdminChatbot",
  (dispatch, input) => {
    return request("POST", `/librarian/chatbot/create`, { input }).then((res) => res)
  }
)

export const createGlobalChatbot = asyncAction(
  "librarian/createGlobalChatbot",
  (dispatch, input) => {
    return request("POST", `/librarian/chatbot/create-global`, { input }).then((res) => res)
  }
)

export const editChatbot = asyncAction(
  "librarian/editChatbot",
  (dispatch, id, patch) => {
    return request("PATCH", `/librarian/chatbot/planner/${id}`, { patch }).then((res) => res)
  }
)

export const editAdminChatbot = asyncAction(
  "librarian/editAdminChatbot",
  (dispatch, id, patch) => {
    return request("PATCH", `/librarian/chatbot/${id}`, { patch }).then((res) => res)
  }
)

export const deleteChatbot = asyncAction(
  "librarian/deleteChatbot",
  (dispatch, id) => {
    return request("DELETE", `/librarian/chatbot/${id}`).then(() => ({ id }))
  }
)

export const setGeneralPrefix = asyncAction("librarian/setGeneralPrefix", (dispatch, prefix) => {
  return request("PATCH", `/customer-admin/set-general-prefix`, prefix).then((res) => res)
})

export const setRelevantDocumentation = asyncAction("librarian/setRelevantDocumentation", (dispatch, relevantDocumentation) => {
  return request("PATCH", `/customer-admin/set-relevant-documentation-string`, relevantDocumentation).then((res) => res)
})

export const setLastQuestion = asyncAction("librarian/setLastQuestion", (dispatch, lastQuestion) => {
  return request("PATCH", `/customer-admin/set-last-questions-hint`, lastQuestion).then((res) => res)
})

export const fetchConfig = asyncAction("librarian/fetchConfig", (dispatch) => {
  return request("GET", `/customer-admin/get-config-strings`).then((res) => res)
})
