import React, { useState } from "react"
import { useSelector, useDispatch } from "react-redux"
import { useNavigate, useLocation } from "react-router-dom"
import DropdownButton from "react-bootstrap/DropdownButton"
import Dropdown from "react-bootstrap/Dropdown"
import PlanningModeButtons from "./PlanningModeButtons"
import useLocalStorage from "utils/hooks/useLocalStorage"

import "./index.scss"

import Button from "react-bootstrap/Button"
import { Popup, UpdateIndicator, SimulationLogoutConfirm } from "components"
import { MemberConfig } from "components/Config"
import DateButtons from "./DateButtons"

import { visual, teamStatus, auth } from "state_management"
import { DateTime } from "luxon"

import { FiSettings, FiMenu, FiHome, FiLogOut } from "react-icons/fi"
import { RiUserSettingsLine } from "react-icons/ri"
import { TbArrowsMaximize, TbArrowsMinimize } from "react-icons/tb";
import isOnMobile from "utils/scripts/isOnMobile"


export const setMondayDate = (date, mondayDate, dispatch) => {
  const thisMonday = date.startOf("week")
  if (!mondayDate.hasSame(thisMonday, "day")) {
    dispatch(visual.changeMondayDate(thisMonday.toISO()))
  }
}
// Buttons used on various schedule screens.
export default function ScheduleButtons({ type, member, moveLeft, moveRight, view }) {
  const dispatch = useDispatch()
  const location = useLocation()
  const navigate = useNavigate()
  const mondayDate = DateTime.fromISO(useSelector((state) => state.visual.scheduleMondayDate))
  const activeDate = DateTime.fromISO(useSelector((state) => state.visual.activeDate))
  const showCompactAssetview = useSelector((state) => state.visual.assetView.showCompact)
  const showWeekendAssetView = useSelector((state) => state.visual.assetView.showWeekend)
  const showCompactTeamPlan = useSelector((state) => state.visual.teamPlan.showCompact)
  const isInPlanningMode = useSelector((state) => state.teamStatus.isInPlanningMode)
  const isCurrentPlanner = useSelector((state) => state.teamStatus.isCurrentPlanner)
  const isMember = useSelector((state) => state.auth.isMember)
  const memberId = useSelector((state) => state.auth.memberId)
  const showProjects = useSelector((state) => state.teamStatus.showProjects)
  const showAnalyses = useSelector((state) => state.teamStatus.showAnalyses)
  const showForecast = useSelector((state) => state.teamStatus.showForecast)
  const showLogoutConfirm = useSelector((state) => state.teamStatus.showLogoutConfirm)
  const directToMemberPlan = useSelector((state) => state.teamStatus.directToMemberPlan)

  const [activeConfig, setActiveConfig] = useState(null)
  const [withWeekend, setWithWeekend] = useLocalStorage('schedule-withWeekend', false)
  const [withWeek, setWithWeek] = useLocalStorage('schedule-withWeek', false)


  const isDayView = view === "day-view"
  let dropDownOptions = []
  dropDownOptions.push({ url: "/", name: "Team Plan" })
  dropDownOptions.push({ url: "/asset-view", name: "Asset View" })
  if (showAnalyses && !showForecast) {
    dropDownOptions.push({ url: "/sample-view", name: "Sample View" })
  }
  if (showProjects) {
    dropDownOptions.push({ url: "/project-view", name: "Project View" })
  }
  if (showProjects) {
    dropDownOptions.push({ url: "/scrum-view", name: "Scrum View" })
  }
  !showForecast && dropDownOptions.push({ url: `/member/${memberId}`, name: "Member Plan" })
  const isCreatingProject = location.pathname === "/setup/projects/create"
  const isEditingProject = location.pathname !== "/setup/projects/create" && location.pathname.includes("/setup/projects/")

  const isStandalone = () => {
    return (window.matchMedia('(display-mode: standalone)').matches)
  }

  const handleLibrarianNavigation = () => {
    if (isStandalone()) {
      navigate('/librarian')
    } else {
      window.open('/librarian', '_blank')
    }
  }



  return (
    <>
      {showLogoutConfirm && <SimulationLogoutConfirm/>}

      {activeConfig && (
        <Popup nonScrollable onCancel={() => setActiveConfig(false)}>
          <MemberConfig member={member} onClose={() => setActiveConfig(false)} />
        </Popup>
      )}
      <div className={"ScheduleButtons" + (moveLeft ? " move-left" : "") + (moveRight ? " move-right" : "")}>
        <div className="leftmenu-button">
          {type === "team-plan" && !isMember && (
            <Button title="Open Metrics/IAP Panel" onClick={() => dispatch(visual.setMenuVisibility("leftmenu", true))}>
              <FiMenu />
            </Button>
          )}
        </div>

        {type !== "scrum-view" && (
          <DateButtons type={type} isDayView={isDayView} activeDate={activeDate} mondayDate={mondayDate} memberId={memberId} />
        )}
        {type === "scrum-view" && <div className="ScheduleButtons-filler"> </div>}

        <div className="center-buttons">
          {isInPlanningMode || (type === "team-plan" && !isMember) ? <PlanningModeButtons /> : null}

          <Button variant="primary" onClick={handleLibrarianNavigation}>
            QC Librarian
          </Button>
          <Button variant="primary" onClick={() => navigate(isMember && directToMemberPlan ? `/member/${memberId}` : '/')}>
            <FiHome  title={'Home'}/>
          </Button>
          {/* The type condition is to hide the button if the settings-view is already shown */}
          {!isMember && !isOnMobile &&
            <Button variant="primary"  onClick={() => navigate("/setup/members")}>
              <FiSettings  title={'Settings'}/>
            </Button>
          }

          <DropdownButton
            title={
              (() => {
                const firstSegment = location.pathname.split('/')[1];
                const matchingOption = dropDownOptions.find(option => option.url.includes(`/${firstSegment}`));
                return matchingOption ? matchingOption.name : (isCreatingProject ? "Creating Project" : (isEditingProject ? "Editing Project" : "Settings"));
              })()
            }
            onSelect={(view) => navigate(view)}
          >
            {dropDownOptions.map((view) => {
              return (
                <Dropdown.Item key={view.url} active={view === location.pathname} eventKey={view.url}>
                  {view.name}
                </Dropdown.Item>
              )
            })}
          </DropdownButton>

          <UpdateIndicator/>

        </div>

        <div className="member-planningmode-week-buttons">
          {type === "asset-view" && (
            <Button
              title={showCompactAssetview ? "View Normal" : "View Compact"}
              onClick={() => dispatch(visual.setShowCompactAssetview(!showCompactAssetview))}
            >
              {showCompactAssetview ? <TbArrowsMaximize/> : <TbArrowsMinimize/>}
            </Button>
          )}

          {type === "team-plan" && (
            <Button
              title={showCompactTeamPlan ? "View Normal" : "View Compact"}
              onClick={() => dispatch(visual.setShowCompactTeamPlan(!showCompactTeamPlan))}
            >
              {showCompactTeamPlan ? <TbArrowsMaximize/> : <TbArrowsMinimize/>}
            </Button>
          )}

          {type !== "sample-view" && type !== "project-view" && view !== "day-view" && type !== "scrum-view" && type !== "settings-view" && (
            <Button
              title={withWeek ? "Hide Next Week" : "Show Next Week"}
              onClick={() => dispatch(visual.setExtraWeekVisibility(!withWeek, setWithWeek(!withWeek)))}
            >
              {withWeek ? "1 Week" : "2 Weeks"}
            </Button>
          )}

          {type !== "asset-view" &&
            type !== "sample-view" &&
            type !== "project-view" &&
            view !== "day-view" &&
            type !== "scrum-view" &&
            type !== "settings-view" && (
              <Button
                title={withWeekend ? "Hide Weekend" : "Show Weekend"}
                onClick={() => dispatch(visual.setWeekendVisibility(!withWeekend, setWithWeekend(!withWeekend)))}
              >
                {withWeekend ? '- Sat/Sun' : '+ Sat/Sun'}
              </Button>
            )}
          {type === "asset-view" && (
            <Button
            title={showWeekendAssetView ? "Hide Weekend" : "Show Weekend"}
            onClick={() => dispatch(visual.setShowWeekendAssetview(!showWeekendAssetView))}
          >
            {showWeekendAssetView ? '- Sat/Sun' : '+ Sat/Sun'}
          </Button>
          )}

          {member && (
            <Button title="Configure Member" id="config-member" onClick={() => setActiveConfig(true)}>
              <RiUserSettingsLine />
            </Button>
          )}

          {((showProjects && !showAnalyses) || (!showProjects && showAnalyses)) && (<Button
            variant="primary"
            onClick={() => {
              // Open help in new tab
              if (showAnalyses && !showProjects) {
                window.open("https://arnvind.com/plannertech-analysis-help/?pass=3af5b6917f3b195544496aa42b1159ea4396d9e24403f4f095d1a85c246033a2")
              } else if (showProjects && !showAnalyses) {
                window.open("https://arnvind.com/plannertech-project-help/?pass=4839c1c7cb609072a61d862d9111b54d7ec8831daea053069c2d279ad27c9a32")
              } else if(showProjects && showAnalyses) {

              }
            }}
            title="Help"
          >
            ?
          </Button>)}

          {showProjects && showAnalyses && (
            <Dropdown>
              <Dropdown.Toggle variant="primary" id="dropdown-basic" title="Help">
                ?
              </Dropdown.Toggle>
              <Dropdown.Menu className="Schedule-help-dropdown">
                <Dropdown.Item
                  target="_blank"
                  href="https://arnvind.com/plannertech-analysis-help/?pass=3af5b6917f3b195544496aa42b1159ea4396d9e24403f4f095d1a85c246033a2"
                >
                  Analysis
                </Dropdown.Item>
                <Dropdown.Item
                  target="_blank"
                  href="https://arnvind.com/plannertech-project-help/?pass=4839c1c7cb609072a61d862d9111b54d7ec8831daea053069c2d279ad27c9a32"
                >
                  Project
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          )}
          <Button
            variant="primary"
              onClick={() => {
                if (isInPlanningMode && isCurrentPlanner) {
                  dispatch(teamStatus.showLogoutConfirm(true))
                } else {
                  dispatch(auth.logout()).then(() => navigate("/login"))
                }
              }} title={'Logout'}
          >
            <FiLogOut />
          </Button>
        </div>

        <div className="rightmenu-button">
          {(type === "team-plan" || type === "single-member") && (
            <>
            <Button title="Open TODO List" onClick={() => dispatch(visual.setMenuVisibility("rightmenu", true))}>
              <FiMenu />
            </Button>
            </>
          )}
        </div>
      </div>
    </>
  )
}
