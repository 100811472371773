import React, { useState } from "react"
import { useParams } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux"


import { FiBarChart2, FiPlus, FiSettings, FiCopy, FiUserPlus } from "react-icons/fi"
import { Popup, Loading } from "components"
import { TeamConfig, MemberConfig, ReportConfig } from "components/Config"
import { BsFillBasketFill } from "react-icons/bs"
import { UsageConfig } from "components/Config"
import { message, teamsOnLocation, repositories, teamInfo } from "state_management"
// import { team } from "state_management"

export default function Team() {
  const { locationId } = useParams()
  const dispatch = useDispatch()
  const [showConfig, setShowConfig] = useState(false)
  const [team, setTeam] = useState(null)
  const [addConfig, setAddConfig] = useState(null)
  const [showReportConfig, setShowReportConfig] = useState(null)
  const [showTeamConsume, setShowTeamConsume] = useState(false)
  const { isLoading, teams } = useSelector((state) => state.teamsOnLocation[locationId]) || {}

  const locations = useSelector((state) => state.locations)
  const timeZone = locations?.find((location) => location.id === locationId)?.timezone

  if (!isLoading && !teams) {
    dispatch(teamsOnLocation.fetch(locationId))
    dispatch(teamInfo.setLocationTimeZone(timeZone))
    dispatch(repositories.fetch())
    dispatch(repositories.fetchAssignable())
    return <Loading />
  }
  if (isLoading) {
    return <Loading />
  }

  return (
    <div className="Location-team-main-container">
      {showConfig && (
        <Popup nonScrollable onCancel={() => setShowConfig(false)}>
          <TeamConfig locationId={locationId} teamId={team?.id} onClose={() => setShowConfig(false)} />
        </Popup>
      )}
      {addConfig && (
        <Popup nonScrollable onCancel={() => setAddConfig(null)}>
          <MemberConfig adminTeamId={addConfig} onClose={() => setAddConfig(null)} />
        </Popup>
      )}
      {showReportConfig && (
        <Popup className="Report-Popup" nonScrollable onCancel={() => setShowReportConfig(null)}>
          <ReportConfig onClose={() => setShowReportConfig(null)} id={showReportConfig}></ReportConfig>
        </Popup>
      )}
      {showTeamConsume && (
        <Popup title={`${team.name} usage`} className="popup-autoWidth" nonScrollable onCancel={() => setShowTeamConsume(false)}>
          <UsageConfig item={team} />
        </Popup>
      )}
      <h1>Teams</h1>
      <div className="Location-team-boxes-scroll">
        {teams.map((team) => (
          <div key={team.id} className="Location-team-box Location-team-box-color Location-team-box-hovereffect">
            <div className="forecast-team">{team.showForecast ? "Forecast" : null}</div>
            <div className="Location-team-box-inner">
              <p>{team.name}</p>
            </div>
            <FiSettings
              title="Settings"
              className="Location-team-fiSettings admin-icons-hovereffect"
              onClick={(e) => {
                e.stopPropagation()
                setShowConfig(true)
                setTeam(team)
              }}
            />
            <BsFillBasketFill
              className="Location-team-consume admin-icons-hovereffect"
              title="Usage"
              onClick={(e) => {
                e.stopPropagation()
                setShowTeamConsume(true)
                setTeam(team)
              }}
            />
            <FiUserPlus
              title="Add member"
              className="Location-team-addUser admin-icons-hovereffect"
              onClick={(e) => {
                e.stopPropagation()
                setAddConfig(team.id)
              }}
            />
            {!team.showForecast && (
              <FiCopy
                title="Copy as forecast team"
                className="Location-team-fiCopy admin-icons-hovereffect"
                onClick={(e) => {
                  e.stopPropagation()
                  dispatch(message.success("Duplicating the team"))
                  dispatch(teamsOnLocation.createForecastTeam(team.id))
                }}
              />
            )}

            <FiBarChart2
              title="Reports"
              className="Location-team-FiBarChart2 admin-icons-hovereffect"
              onClick={(e) => {
                e.stopPropagation()
                setShowReportConfig(team.id)
                setTeam(team)
              }}
            />
          </div>
        ))}
        <div className="Location-team-box">
          <div className="Location-team-fiPlus-container">
            <FiPlus
              className="Location-team-fiPlus"
              title="Add team"
              onClick={() => {
                setShowConfig(true)
                setTeam(null)
              }}
              color="black"
            />
          </div>
        </div>
      </div>
    </div>
  )
}
