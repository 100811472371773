import React, { useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useNavigate } from "react-router-dom"

import { locations, repositories, librarian } from "state_management"

import { Spinner } from "react-bootstrap"
import { FiPlus, FiSettings, FiCheck } from "react-icons/fi"
import { BsFillBasketFill } from "react-icons/bs"
import { Loading, Popup } from "components"
import { ChatbotConfig, LocationConfig } from "components/Config"
import { UsageConfig } from "components/Config"
import { RepositoryConfig } from "components/Config"

export default function Location() {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [showLocationConfig, setShowLocationConfig] = useState(false)
  const [showLocationConsume, setShowLocationConsume] = useState(false)
  const [showRepositoryConfig, setShowRepositoryConfig] = useState(false)
  const [location, setLocation] = useState(null)
  const [repository, setRepository] = useState(null)
  const companyId = useSelector((state) => state.auth.companyId)
  const locationsData = useSelector((state) => state.locations)
  const repositoriesData = useSelector((state) => state.repositories.repositories)
  const chatbots = useSelector((state) => state.librarian.chatbots)
  const [showChatbotConfig, setShowChatbotConfig] = useState(false)
  const [chatbot, setChatbot] = useState(null)

  const [hasFetched, setHasFetched] = useState(false)

  if (!hasFetched && !locationsData) {
    dispatch(locations.fetch(companyId))
    dispatch(repositories.fetchAll())
    dispatch(librarian.fetchAdminChatbots())
    setHasFetched(true)
  }
  if (!locationsData) {
    return <Loading />
  }

  return (
    <div className="Location-team-main-container">
      {showLocationConfig && (
        <Popup nonScrollable onCancel={() => setShowLocationConfig(false)}>
          <LocationConfig location={location} onClose={() => setShowLocationConfig(false)} />
        </Popup>
      )}
      {showChatbotConfig && (
        <Popup nonScrollable onCancel={() => setShowChatbotConfig(false)}>
          <ChatbotConfig chatbotData={chatbot} companyId={companyId} repositories={repositoriesData} onCancel={() => setShowChatbotConfig(false)} />
        </Popup>
      )}

      {showLocationConsume && (
        <Popup title={`${location.name} usage`} className="popup-autoWidth" nonScrollable onCancel={() => setShowLocationConsume(false)}>
          <UsageConfig item={location} />
        </Popup>
      )}

      {showRepositoryConfig && (
        <Popup nonScrollable onCancel={() => setShowRepositoryConfig(false)}>
          <RepositoryConfig repositoryData={repository} onClose={() => setShowRepositoryConfig(false)} />
        </Popup>
      )}

      <h1>Locations</h1>
      <div className="Location-team-boxes-scroll">
        {locationsData.map((location) => (
          <div
            key={location.id}
            className="Location-team-box Location-team-box-color Location-team-box-hovereffect"
            onClick={() => navigate(`${location.id}/teams`)}
          >
            <div className="Location-team-box-inner">
              <p>{location.name}</p>
            </div>
            <BsFillBasketFill
              className="Location-team-consume admin-icons-hovereffect"
              title="Usage"
              onClick={(e) => {
                e.stopPropagation()
                setShowLocationConsume(true)
                setLocation(location)
              }}
            />
            <FiSettings
              className="Location-team-fiSettings admin-icons-hovereffect"
              title="Settings"
              onClick={(e) => {
                e.stopPropagation()
                setShowLocationConfig(true)
                setLocation(location)
              }}
            />
          </div>
        ))}
        <div className="Location-team-box">
          <div className="Location-team-fiPlus-container">
            <FiPlus
              className="Location-team-fiPlus"
              title="Add location"
              onClick={() => {
                setShowLocationConfig(true)
                setLocation(null)
              }}
              color="black"
            />
          </div>
        </div>
      </div>
      <h1>Repositories</h1>
      <div className="Location-team-boxes-scroll">
        {repositoriesData.map((repository) => !repository.isGlobal && (
          <div
            key={repository.id}
            className="Location-team-box Location-repository-box-color Location-team-box-hovereffect"
            onClick={() => navigate(`/locations/repository/${repository.id}`)}
          >
            <div className="repository-storageUsed-text">
              <p style={{ color: "white" }}>{(repository.storageUsed / 1000 / 1000).toFixed(1)} MB</p>
            </div>
            <div className="Location-repository-box-inner">
              <p>{repository.name}</p>
            </div>
            <div className="repository-processing-icon">
              {repository.isProcessing ? (
                <div className="repository-spinner">
                  <Spinner animation="border" size="sm" title="Currently procesing this repository" />
                </div>
              ) : repository.isProcessed ? (
                <FiCheck title="This repository is processed" />
              ) : null}
            </div>
            <FiSettings
              className="Location-team-fiSettings admin-icons-hovereffect"
              title="Settings"
              onClick={(e) => {
                e.stopPropagation()
                setShowRepositoryConfig(true)
                setRepository(repository)
              }}
            />
          </div>
        ))}
        <div className="Location-team-box">
          <div className="Location-team-fiPlus-container">
            <FiPlus
              className="Location-team-fiPlus"
              title="Add repository"
              onClick={() => {
                setShowRepositoryConfig(true)
                setRepository(null)
              }}
              color="black"
            />
          </div>
        </div>
      </div>
      <h1>Chatbots</h1>
      <div className="Location-team-boxes-scroll">
          {chatbots.map((chatbot) => !chatbot.isGlobal && (
            <div
              key={chatbot.id}
            className="Location-team-box Location-chatbot-box-color Location-team-box-hovereffect"
              onClick={() => {
                setChatbot(chatbot)
                setShowChatbotConfig(true)
              }}
            >
            <div className="Location-repository-box-inner">
                <p>{chatbot.name}</p>
              </div>
            </div>
          ))}
        <div className="Location-team-box">
          <div className="Location-team-fiPlus-container">
              <FiPlus
              className="Location-team-fiPlus"
                title="Add global chatbot"
                onClick={() => {
                  setChatbot(null)
                  setShowChatbotConfig(true)
                }}
              />
            </div>
          </div>
        </div>
    </div>
  )
}
