import React, { useState } from "react"
import { loading, logging, integrationSheets, teamStatus, message } from "state_management"
import { useDispatch, useSelector } from "react-redux"
import { useLocation } from "react-router-dom"

import { ConfirmDialog, Popup } from "components"
import Button from "react-bootstrap/Button"

import ToggleButton from "react-bootstrap/ToggleButton"
import ToggleButtonGroup from "react-bootstrap/ToggleButtonGroup"
import { Title, Container} from "components/Form"
import LogTable from "components/LogTable"

import "./index.scss"
export default function SimulationTab() {
  const dispatch = useDispatch()
  const location = useLocation()

  const isInPlanningMode = useSelector((state) => state.teamStatus.isInPlanningMode)
  const isCurrentPlanner = useSelector((state) => state.teamStatus.isCurrentPlanner)
  const isAnalyses = useSelector((state) => state.teamStatus.showAnalyses)
  const isProjects = useSelector((state) => state.teamStatus.showProjects)
  const log = useSelector((state) => state.logging)
  const allowTaskTransfer = useSelector((state) => state.teamStatus.allowTaskTransfer)
  const directToMemberPlan = useSelector((state) => state.teamStatus.directToMemberPlan)


  const [activeConfirmHandler, setActiveConfirmHandler] = useState(null)
  const [activeConfirmTitle, setActiveConfirmTitle] = useState(null)
  const [activeConfirm, setActiveConfirm] = useState(false)
  const [showLog, setShowLog] = useState(false)


  const otherPlannerInitials = useSelector((state) => {
    const currentPlannerId = state.teamStatus.currentPlannerId
    if (currentPlannerId === "self") {
      return null
    } else if (currentPlannerId) {
      return state.members[currentPlannerId].initials
    } else {
      return null
    }
  })

  return (
      <div className="simulation-tab">
        {showLog && (
          <Popup
            title="Task Movement Log"
            onCancel={() => setShowLog(false)}
            className="log-popup"
          >
            <LogTable log={log}/>
          </Popup>
        )}
        {activeConfirm && (
          <ConfirmDialog
            onCancel={() => setActiveConfirm(false)}
          onConfirm={() => {
            activeConfirmHandler()
            setActiveConfirm(false)
          }}
        >
          <h4>{activeConfirmTitle}</h4>
        </ConfirmDialog>
      )}
      <h1>Member permissions</h1>
      <div className="config-container">
        <Container className="config-container">
          <Title>Allow members to move others' operational tasks</Title>
          <ToggleButtonGroup
            type="radio"
            className="TeamConfig-toggleButtonGroup"
            name="allowTaskTransfer"
            defaultValue={allowTaskTransfer}
            onChange={(value) => {
              dispatch(teamStatus.allowTaskTransfer({ allowTaskTransfer: value }))
                .then(() => {
                  dispatch(message.success("Member permissions changed"));
                });
            }}
          >
            <ToggleButton
              value={true}
              variant="light"
              id="teamconfig-movetasks_yes"
              className="TeamConfig-radioButton"
            >
              Yes
            </ToggleButton>
            <ToggleButton
              value={false}
              variant="light"
              id="teamconfig-movetasks_no"
              className="TeamConfig-radioButton"
            >
              No
            </ToggleButton>
          </ToggleButtonGroup>
        </Container>
      </div>

      <div className="config-container">
        <Container className="config-container">
          <Title>Members go directly to member plan</Title>
          <ToggleButtonGroup
            type="radio"
            className="TeamConfig-toggleButtonGroup"
            name="directToMemberPlan"
            defaultValue={directToMemberPlan}
            onChange={(value) => {
              dispatch(teamStatus.directToMemberPlan({ directToMemberPlan: value }))
                .then(() => {
                  dispatch(message.success("Member permissions changed"));
                });
            }}
          >
            <ToggleButton
              value={true}
              variant="light"
              id="teamconfig-memberplan_yes"
              className="TeamConfig-radioButton"
            >
              Yes
            </ToggleButton>
            <ToggleButton
              value={false}
              variant="light"
              id="teamconfig-memberplan_no"
              className="TeamConfig-radioButton"
            >
              No
            </ToggleButton>
          </ToggleButtonGroup>
        </Container>
      </div>
      <hr />
        <h1>Simulation Mode</h1>

        {!isInPlanningMode && (
          <Button title="Enter Simulation Mode" onClick={() => dispatch(teamStatus.startPlanningMode())}>
            SIMULATION MODE
          </Button>
        )}
        {!isCurrentPlanner && isInPlanningMode && (
          <Button disabled title={`${otherPlannerInitials} is in simulation mode`} variant="danger">
            SIMULATION MODE OCCUPIED
          </Button>
        )}
        {isCurrentPlanner && isInPlanningMode && (
          <Button
            disabled={!isCurrentPlanner}
            variant="danger"
            onClick={() => {
              setActiveConfirm(true)
              setActiveConfirmHandler(() => () =>
                dispatch(teamStatus.discardPlanningModeChanges()).then(() => {
                  dispatch(loading.fetchAll(location.pathname))
                  if (isProjects) {
                    dispatch(integrationSheets.fetchProjectTemplateSheets())
                    dispatch(integrationSheets.fetchActionSheets())
                  }
                  if (isAnalyses) {
                    dispatch(integrationSheets.fetchSampleSheets())
                  }
                })
              )
              setActiveConfirmTitle("Are you sure you want to discard the changes made in simulation mode?")
            }}
            title={isCurrentPlanner ? "Exit simulation mode" : `${otherPlannerInitials} is in simulation mode`}
          >
            EXIT SIMULATION
          </Button>
        )}
        <hr/>
        <h1>Task Movement Log</h1>
        <Button
          onClick={() => {
            dispatch(logging.fetchLog()).then(() => setShowLog(true));
          }}
        >
          Show Log
        </Button>
      </div>
  )
}
